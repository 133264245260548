import {
    Autocomplete,
    BlockStack,
    Box,
    Card,
    Text,
    Icon,
    InlineStack,
    Thumbnail,
    SkeletonBodyText,
} from '@shopify/polaris';
import { CardWithTitleSection } from '../common';
import { useSnapshot } from 'valtio';
import {
    mobileAppConfigStore,
    shopCollectionStore,
} from '@/stores';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useGetCollections } from '@/hooks/useGetCollections';
import { useGetCollectionProducts } from '@/hooks/useGetCollectionProducts';
import { CheckCircleIcon } from '@shopify/polaris-icons';
import shopStore from '@/stores/shopStore';

type FeaturedCollectionCardProps = {
    readonly?: boolean;
};
export const CollectionCard: FC<FeaturedCollectionCardProps> = ({
    readonly,
}) => {
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    const shopCollectionSnapshot = useSnapshot(shopCollectionStore, {
        sync: true,
    });
    const { getCollectionProducts } = useGetCollectionProducts();
    const { getCollections } = useGetCollections();
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const { shopifyDomain } = useSnapshot(shopStore);
    const { shopifyCollectionGid } = mobileAppConfigSnap;
    useEffect(() => {
        const getStoredCollection = async () => {
            if (shopifyCollectionGid) {
                const { collection } =
                    await getCollectionProducts(shopifyCollectionGid);
                if (collection) {
                    shopCollectionStore.addCollection({
                        label: collection.title,
                        value: shopifyCollectionGid,
                    });
                    shopCollectionStore.setSelectedCollection(
                        shopifyCollectionGid,
                    );
                    setSelectedOption(shopifyCollectionGid);
                    setInputValue(collection.title);
                }
            }
        };
        getStoredCollection();
    }, [shopifyCollectionGid]);
    useEffect(() => {
        if (!shopifyDomain) {
            return;
        }
        getCollections('');
    }, [shopifyDomain]);

    const timeoutRef = useRef(0);
    const updateResults = useCallback((value: string) => {
        setInputValue(value);
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(async () => {
            await getCollections(value);
        }, 500);
    }, []);

    const handleSelect = useCallback((value: string) => {
        setInputValue(
            shopCollectionStore.collections.find(
                (option) => option.value === value,
            )?.label || '',
        );
        shopCollectionStore.setSelectedCollection(value);
        mobileAppConfigStore.shopifyCollectionGid = value;
        setSelectedOption(value);
        getCollectionProducts(value);
    }, []);

    const textField = (
        <Autocomplete.TextField
            onChange={updateResults}
            label="Collections"
            value={inputValue}
            placeholder="Search Collections"
            autoComplete="off"
            readOnly={readonly}
        />
    );
    return (
        <CardWithTitleSection
            title="Featured Collection"
            subtitle="Search and select the collection you would like to use as your apps featured collection."
        >
            {mobileAppConfigSnap.isLoading ? (
                <SkeletonBodyText />
            ) : (
                <Box>
                    <BlockStack gap="400">
                        {shopCollectionSnapshot.productCollectionCount && (
                            <Card background="bg-fill-success-secondary">
                                <Box>
                                    <InlineStack
                                        gap="400"
                                        align="space-between"
                                    >
                                        <InlineStack
                                            blockAlign="center"
                                            gap="400"
                                        >
                                            <Icon
                                                source={CheckCircleIcon}
                                                tone="success"
                                            />
                                            <Text fontWeight="semibold">
                                                {
                                                    shopCollectionSnapshot.productCollectionCount
                                                }{' '}
                                                products have been added from
                                                this collection
                                            </Text>
                                        </InlineStack>
                                        <InlineStack gap="200">
                                            {shopCollectionSnapshot.productCollectionUrls.map(
                                                (url) => (
                                                    <Thumbnail
                                                        size="small"
                                                        source={url}
                                                        alt="Example product image from collection"
                                                        key={url}
                                                    />
                                                ),
                                            )}
                                        </InlineStack>
                                    </InlineStack>
                                </Box>
                            </Card>
                        )}
                        <div style={readonly ? { pointerEvents: 'none' } : {}}>
                            <Autocomplete
                                options={shopCollectionStore.collections}
                                selected={selectedOption}
                                emptyState={
                                    <Box padding="300">
                                        <Text as={'p'}>No Results</Text>
                                    </Box>
                                }
                                loading={
                                    shopCollectionSnapshot.areCollectionsLoading
                                }
                                onSelect={([value]: [string]) => {
                                    handleSelect(value);
                                }}
                                textField={textField}
                            />
                        </div>
                    </BlockStack>
                </Box>
            )}
        </CardWithTitleSection>
    );
};

export default CollectionCard;
