import { PopClipsGridListing } from '@/components/popclips';
import { BlockStack, Text } from '@shopify/polaris';
import FeatureReleaseCalloutCard from '@/components/common/FeatureReleaseCalloutCard';
import { shopStore } from '@/stores';
import { useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';
import { openEmbedWindow } from '@/utils/helpers';
import PopclipsCarousel2kBanner from '@/components/common/PopclipsCarousel2kBanner';
import { vfsRoutes } from '@/constants/routes.constants';
import { BaseLayout } from '../../v2/pages/Layout/BaseLayout';
import { BYPASS_GATING_FOR_DOMAINS } from '@/constants/shopify.constants';
import SocialMediaPlanUpdateModal from './SocialMediaPlanUpdateModal';
import PopclipViewsLimitModal from './PopclipViewsLimitModal';
import ManageCustomCarousels from '../../components/popclips/popclips-grid-components/ManageCustomCarousels';
import VfsModal from '../../v2/Components/VfsModal';
import { useNavigate } from 'react-router-dom';

const PopClipsGridPage = () => {
    const themeWindowRef = useRef<Window | null>();
    const [showPopclipsCarousel2kBanner, setShowPopclipsCarousel2kBanner] =
        useState(false);
    const [showCustomCarousel, setShowCustomCarousel] = useState(false);
    const [popUpAppear,setPopUpAppear] =useState(false);
    const [showPlanUpgardeModal, setShowPlanUpgardeModal] = useState(false);

    const navigate = useNavigate();

    const {
        subscription,
        popclipCarouselEmbedEnableUrl,
        getStartedStatus,
        shopifyDomain,
        featureGatingData
    } = useSnapshot(shopStore);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            'popclips',
            true,
        );
        const maxPrice = Math.max(...addonPrices);

        if (BYPASS_GATING_FOR_DOMAINS.includes(shopifyDomain)) {
            setShowCustomCarousel(true);
            setShowPopclipsCarousel2kBanner(true);
        } else {
            setShowCustomCarousel(maxPrice >= 29.99);
            setShowPopclipsCarousel2kBanner(maxPrice < 29.99);
        }
        setShowPlanUpgardeModal(featureGatingData?.popclips?.maxViews != '-1' && featureGatingData?.popclips?.limitAccess);
    }, [subscription]);

    const handleOpenEmbedWindow = async () => {
        openEmbedWindow(themeWindowRef, popclipCarouselEmbedEnableUrl);
        if (!getStartedStatus.events.STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL) {
            await shopStore.updateGetStartedStatus(
                'STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL',
            );
        }
    };
    const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);
    const [showViewsLimitModal, setShowViewsLimitModal] = useState(false);
    
useEffect(() => {
        let maxViews: number | string = 0;
        const popclipsMaxViews = featureGatingData?.popclips?.maxViews;
        if (popclipsMaxViews)
            maxViews =
                popclipsMaxViews != '-1'
                    ? Number(popclipsMaxViews.replace(/,/g, ''))
                    : 'Unlimited';
        if (maxViews === 2000 || maxViews === 15000) {
            const percentage =
                (Number(
                    featureGatingData?.popclips?.totalViews.replace(/,/g, ''),
                ) /
                    Number(maxViews)) *
                100;
            if (percentage >= 80 && !showViewsLimitModal && !popUpAppear) {
                setShowViewsLimitModal(true);
                setPopUpAppear(true)
            }
        }
}, [featureGatingData]);
    return (
        <BaseLayout
            title="Your Popclips"
            subtitle="All Popclips - Shoppable Videos linked to your account"
            backToLink={vfsRoutes.landing}
        >
            <BlockStack gap="400">
                {!showCustomCarousel && popclipCarouselEmbedEnableUrl && (
                    <FeatureReleaseCalloutCard
                        openEmbedWindow={handleOpenEmbedWindow}
                        setShowPlanUpgradeModal={setShowPlanUpgradeModal}
                    />
                )}
                <PopClipsGridListing />
                <PopclipsCarousel2kBanner
                    modalOpen={showPopclipsCarousel2kBanner}
                    toggleHideModal={() =>
                        setShowPopclipsCarousel2kBanner(false)
                    }
                />
                {showCustomCarousel && <ManageCustomCarousels />}
            </BlockStack>
            {showPlanUpgradeModal && (
                <SocialMediaPlanUpdateModal
                    setShowSocialPlanUpdate={setShowPlanUpgradeModal}
                    socialButtonType="POPCLIPS_BASIC_PLAN"
                />
            )}
            {showViewsLimitModal && (
                <PopclipViewsLimitModal
                    setShowSocialPlanUpdate={setShowViewsLimitModal}
                    planType={
                        featureGatingData?.popclips?.maxViews === '2,000'
                            ? 'free'
                            : featureGatingData?.popclips?.maxViews === '15,000'
                              ? 'basic'
                              : ''
                    }
                />
            )}
            {showPlanUpgardeModal && <VfsModal
                onCloseModal={() => setShowPlanUpgardeModal(false)}
                title=""
                modalWidth="37rem"
                closeable={false}
                crossIcon={false}
                primaryAction={() => navigate(vfsRoutes.managePlans)}
                primaryActionText={'Upgrade Now'}
                secondaryAction={() => setShowPlanUpgardeModal(false)}
                secondaryActionText="Cancel"
            >
                <Text as="p">
                    <strong>Important:</strong> You've reached your current
                    plan's view limits of {' '}{featureGatingData?.maxViews || ''}. In order to create new
                    Popclips please upgrade.
                </Text>
            </VfsModal>}
        </BaseLayout>
    );
};

export default PopClipsGridPage;
