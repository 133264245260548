import { Card, EmptyState, Page, Text } from '@shopify/polaris';
import { notFoundImage } from '../assets/images';

export default function PageNotFound({accessDenied} : {accessDenied?: boolean}) {
    return (
        <Page>
            <Card>
                {accessDenied ? (
                    <EmptyState heading="There is no page at this address" image={notFoundImage}>
                        <Text as='p' tone='subdued'>Something went wrong. Please contact support.</Text>
                    </EmptyState>
                ) : (
                    <EmptyState heading="There is no page at this address" image={notFoundImage}>
                        <Text as='p' tone='subdued'>Check the URL and try again, or use the search bar to find what you need.</Text>
                    </EmptyState>
                )}
            </Card>
        </Page>
    );
}
