import { BlockStack, Box, InlineStack, Spinner, Text } from '@shopify/polaris';
import Plot from 'react-plotly.js';
import DashboardHeaderSection from './DashboardHeaderSection';
import DashboardIndicatorTag from './DashboardIndicatorTag';
import { CONFIG, LAYOUT, LINE_SMOOTHING_VALUE } from './chartConstantConfig';
import { useEffect, useState } from 'react';
import { shopStore } from '@/stores';
import { useSnapshot } from 'valtio';
import VfsModal from '../../v2/Components/VfsModal';

interface DashboardTotalViewsChartPropsTypes {
    title: string;
    value: number;
    chartData: number[];
    labels: string[];
    isLoading: boolean;
    growthInValue: number;
}

const DashboardTotalViewsChart = ({
    title,
    value,
    chartData,
    labels,
    isLoading,
    growthInValue,
}: DashboardTotalViewsChartPropsTypes) => {
    const [isExpand, setIsExpand] = useState(false);
    const [totalViews, setTotalViews] = useState(0);
    const [monthlyTotalViews, setMonthlyTotalViews] = useState(0);

    const { featureGatingData } = useSnapshot(shopStore);

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    useEffect(() => {
        let maxViews = 0;
        const popclipsMaxViews = featureGatingData?.popclips?.maxViews;
        if (popclipsMaxViews) maxViews = popclipsMaxViews != '-1' ? popclipsMaxViews : 'Unlimited';

        setMonthlyTotalViews(featureGatingData?.popclips?.totalViews || 0);
        setTotalViews(maxViews);
    }, [featureGatingData]);

    const DrawTotalViewsChart = () => {
        return (
            <Plot
                data={[
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'none',
                        fill: 'tozeroy',
                        fillcolor: 'rgba(63, 200, 228, 0.1)',
                        hoverinfo: 'skip',
                    },
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'lines',
                        line: {
                            color: '#3FC8E4',
                            width: 3,
                            shape: 'spline',
                            smoothing: LINE_SMOOTHING_VALUE,
                        },
                        hovertemplate: '%{y}<extra></extra>',
                    },
                ]}
                layout={{
                    ...LAYOUT,
                    height: 240,
                    width: isExpand ? '' : 560,
                    margin: { t: 20, l: 20, r: 20, b: 40 },
                    xaxis: {
                        title: 'Weeks',
                    },
                    yaxis: {
                        visible: true,
                        showgrid: false,
                        ticks: '',
                        showticklabels: false,
                        title: 'Views',
                        hoverformat: '.f',
                    },
                }}
                config={{
                    ...CONFIG,
                    displayModeBar: isExpand,
                    staticPlot: !isExpand,
                }}
            />
        );
    };

    return (
        <div>
            <Box
                borderWidth="025"
                borderColor="border"
                borderRadius="500"
                padding="600"
                background="bg-fill"
                shadow="200"
                width="100%"
            >
                <DashboardHeaderSection
                    title="Popclips Views"
                    showResizeButton={true}
                    isExpand={isExpand}
                    handleResizeChart={handleResizeChart}
                />
                <BlockStack gap="400">
                    <InlineStack blockAlign="center" wrap={false} gap="200">
                        <InlineStack blockAlign="center" gap="100" wrap={false}>
                            <Text variant="heading3xl" as="h2">
                                {monthlyTotalViews}
                            </Text>
                            <Text variant="heading3xl" as="h2" tone="subdued">
                                /
                            </Text>
                            <Text variant="heading3xl" as="h2" tone="subdued">
                                {totalViews}
                            </Text>
                        </InlineStack>
                        <Text variant="heading3xl" as="h2">
                            Views
                        </Text>
                        <Text variant="bodyMd" as="h1" tone="subdued">
                            (Monthly)
                        </Text>
                    </InlineStack>
                    <DashboardIndicatorTag
                        growthInValue={growthInValue}
                        isMonth={false}
                    />
                </BlockStack>
                {isLoading ? (
                    <InlineStack align="center" blockAlign="center">
                        <Spinner />
                    </InlineStack>
                ) : (
                    <DrawTotalViewsChart />
                )}
            </Box>
            {isExpand && (
                <VfsModal
                    onCloseModal={handleResizeChart}
                    title={title}
                    modalWidth="90%"
                >
                    <Box width="100%">
                        <BlockStack align="start">
                            <BlockStack gap="400">
                                <InlineStack
                                    blockAlign="center"
                                    wrap={false}
                                    gap="200"
                                >
                                    <InlineStack
                                        blockAlign="center"
                                        gap="100"
                                        wrap={false}
                                    >
                                        <Text variant="heading3xl" as="h2">
                                            {monthlyTotalViews}
                                        </Text>
                                        <Text
                                            variant="heading3xl"
                                            as="h2"
                                            tone="subdued"
                                        >
                                            /
                                        </Text>
                                        <Text
                                            variant="heading3xl"
                                            as="h2"
                                            tone="subdued"
                                        >
                                            {totalViews}
                                        </Text>
                                    </InlineStack>
                                    <Text variant="heading3xl" as="h2">
                                        Views
                                    </Text>
                                    <Text
                                        variant="bodyMd"
                                        as="h1"
                                        tone="subdued"
                                    >
                                        (Monthly)
                                    </Text>
                                </InlineStack>
                                <DashboardIndicatorTag
                                    growthInValue={growthInValue}
                                    isMonth={false}
                                />
                            </BlockStack>
                            {isLoading ? (
                                <InlineStack align="center" blockAlign="center">
                                    <Spinner />
                                </InlineStack>
                            ) : (
                                <DrawTotalViewsChart />
                            )}
                        </BlockStack>
                    </Box>
                </VfsModal>
            )}
        </div>
    );
};

export default DashboardTotalViewsChart;
