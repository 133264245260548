import { mobileRoutes, vfsRoutes } from '@/constants/routes.constants';
import { buildMobileAppStore, shopStore } from '@/stores';
import { Icon, InlineStack, Link } from '@shopify/polaris';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { mobileAppCard } from '../../../assets/images';
import { useGetAppRequest } from '@/hooks/useGetAppRequest';

const LEARN_MORE_LINK = 'https://help.videeo.live/hc/en-us/categories/23184052620308';

export default function MobileAppCard() {
    const navigate = useNavigate();
    const { enabledFeatures } = useSnapshot(shopStore);
    const { getAppRequests } = useGetAppRequest();
    const {
        iosLink,
        androidLink,
        isAppRequestLoading,
        isAppRequestSubmitted,
        androidAppStatus,
        iosAppStatus,
        customerIssueStatus,
        blockedIssueDescription
    } = useSnapshot(buildMobileAppStore);

    useEffect(() => {
        getAppRequests();
    }, []);

    const mobileAppButton = useMemo(() => {
        if (enabledFeatures.includes('mobile')) {
            const androidStatus = androidAppStatus.toUpperCase();
            const iosStatus = iosAppStatus.toUpperCase();
            let link = mobileRoutes.setup;
            let buttonText = '';
            let backgroundColour = '#fff';
            let textColour = '#000';

            if (customerIssueStatus || androidStatus == 'CUSTOMER' || iosStatus == 'CUSTOMER') {
                link = mobileRoutes.notification;
                buttonText = 'Action Required';
                backgroundColour = '#f20000';
                textColour = '#fff';
            } else if (blockedIssueDescription || androidStatus == 'BLOCKED' || iosStatus == 'BLOCKED') {
                link = mobileRoutes.notification;
                buttonText = 'Approval Blocked';
                backgroundColour = '#f20000';
                textColour = '#fff';
            } else if (iosLink || androidLink) {
                link = mobileRoutes.notification;
                buttonText = 'Manage';
            } else if (isAppRequestSubmitted) {
                buttonText = 'Approval Pending';
            } else {
                buttonText = 'Set Up';
            }
            return (
                <InlineStack gap="400" blockAlign="center">
                    <button
                        className="get-started-button"
                        style={{ backgroundColor: backgroundColour, color: textColour, border: 'none' }}
                        onClick={() => navigate(link)}
                        disabled={isAppRequestLoading}
                    >
                        {buttonText} <Icon source={ArrowDiagonalIcon} />{' '}
                    </button>
                </InlineStack>
            );
        } else {
            return (<InlineStack gap="400" blockAlign="center">
                <button
                    className="get-started-button"
                    onClick={() => navigate(vfsRoutes.managePlans)}
                >
                    Get Started <Icon source={ArrowDiagonalIcon} />{' '}
                </button>
            </InlineStack>);
        }
    }, [iosLink, androidLink, isAppRequestLoading, isAppRequestSubmitted]);

    return (
        <div className="mobile-app-card">
            <div className="card-shapes">
                <div className="square"></div>
                <div className="circle"></div>
            </div>
            <p className="card-title">
                <span>Mobile</span>
                <span>App</span>
            </p>
            <div className="card-content">
                <p>
                    Drive Loyalty with a tap-to-shop mobile app.{' '}
                    <Link url={LEARN_MORE_LINK}>Learn More</Link>
                </p>
                {mobileAppButton}
            </div>
            <div className="card-image">
                <video autoPlay muted loop style={{ width: '102px' }}>
                    <source src={mobileAppCard} type="video/webm" />
                </video>
            </div>
        </div>
    );
}
