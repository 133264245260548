import { proxy } from 'valtio';
import shopStore from './shopStore';
import { sha256 } from 'js-sha256';
import { getDeviceInfo, getFingerprint } from '@/utils/helpers';
import { UAParser } from 'ua-parser-js';
import { popclipsRoutes, vfsRoutes } from '@/constants/routes.constants';

const APP_NAME_BY_ENV =
    import.meta.env.MODE == 'qa' ? 'vfs-november' : 'videeo-by-commentsold';

const analyticsEventStore = proxy({
    popclipsUploadedAppEvent: (responseData: any) => {
        if (!responseData) return;

        try {
            const appEventsApiUrl = `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/appevents`;
            fetch(appEventsApiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    occurred_at: Date.now(),
                    event_type: 'POPCLIP_UPLOADED',
                    customer_name: shopStore.shopName,
                    customer_id: shopStore.shopifyDomain,
                    event_details: responseData,
                }),
                redirect: 'follow',
            });
        } catch (error) {
            console.log('Failed to fetch data lake API: ', error);
        }
    },
    popclipUploadTriggered: async (isEditMode: boolean, data: any) => {
        const {
            duration,
            popclipsID,
            source,
            sourceReelUrl,
            newPopclipTitle,
            newProductList,
            newPDPVisibility,
        } = data;
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const productEditLink = `${shopifyAdminURL}${popclipsRoutes.edit.replace(
            ':id',
            popclipsID,
        )}`;
        const currentUrl = isEditMode
            ? productEditLink
            : `${shopifyAdminURL}${popclipsRoutes.create}`;

        dataLakePayloadToTrack(
            'popclip_upload_triggered',
            'task_completed',
            {
                duration,
                location: currentUrl,
                source,
                editLink: productEditLink,
                sourceReelUrl,
                popclip_title_updated: true,
                previous_popclip_title: null,
                new_popclip_title: newPopclipTitle,
                product_list_updated: true,
                previous_product_list: null,
                new_product_list: newProductList,
                pdp_visibility_updated: true,
                previous_pdp_visibility: null,
                new_pdp_visibility: newPDPVisibility,
            },
            popclipsID,
            'popclips/list',
            {
                type: 'button',
                title: 'Save',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[1]/div/div/div/div[2]/button/span/span',
            },
        );
    },
    popclipEditExistingPopclip: async (isEditMode: boolean, data: any) => {
        const {
            duration,
            popclipsID,
            source,
            sourceReelUrl,
            popclipTitleUpdated,
            previousPopclipTitle,
            newPopclipTitle,
            productListUpdated,
            previousProductList,
            newProductList,
            pdpVisibilityUpdated,
            previousPdpVisibility,
            newPdpVisibility,
        } = data;
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const productEditLink = `${shopifyAdminURL}${popclipsRoutes.edit.replace(
            ':id',
            popclipsID,
        )}`;
        const currentUrl = isEditMode
            ? productEditLink
            : `${shopifyAdminURL}${popclipsRoutes.create}`;

        dataLakePayloadToTrack(
            'popclip_edit_existingpopclip',
            'task_completed',
            {
                duration,
                location: currentUrl,
                source,
                editLink: productEditLink,
                sourceReelUrl,
                popclip_title_updated: popclipTitleUpdated,
                previous_popclip_title: previousPopclipTitle,
                new_popclip_title: newPopclipTitle,
                product_list_updated: productListUpdated,
                previous_product_list: previousProductList,
                new_product_list: newProductList,
                pdp_visibility_updated: pdpVisibilityUpdated,
                previous_pdp_visibility: previousPdpVisibility,
                new_pdp_visibility: newPdpVisibility,
            },
            popclipsID,
            'popclips/list',
            {
                type: 'button',
                title: 'Save',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[1]/div/div/div/div[2]/button/span/span',
            },
        );
    },
    popclipCreateCustomCarouselTriggered: async ({
        duration,
        carouselId,
    }: any) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.createCarousel}`;

        dataLakePayloadToTrack(
            'popclip_create_customcarousel',
            'task_completed',
            {
                duration,
                location: currentUrl,
            },
            carouselId,
            'popclips/list',
            {
                type: 'button',
                title: 'Save $ Publish',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div/div[1]/div/div[1]/div[2]/div[2]/button',
            },
        );
    },
    onboardingClickVideoSDK: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.verifyAccount}`;

        dataLakePayloadToTrack(
            'onboarding_click_videoSDK',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Review and Save',
                xPath: '//*[@id="app"]/div[1]/div[2]/div/div[2]/div[1]/div/div[2]/div/div[2]/button',
            },
        );
    },
    onboardingCompleteVideoSDK: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;

        dataLakePayloadToTrack(
            'onboarding_complete_videoSDK',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            '',
            {
                type: null,
                title: null,
                xPath: null,
            },
        );
    },
    onboardingClickVideoSalesChannel: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.verifyAccount}`;

        dataLakePayloadToTrack(
            'onboarding_click_videosaleschannel',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Publish Now',
                xPath: '//*[@id="app"]/div[1]/div[2]/div/div[2]/div[2]/div/div[2]/div/div[2]/button',
            },
        );
    },
    onboardingCompleteVideoSalesChannel: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;

        dataLakePayloadToTrack(
            'onboarding_complete_videosaleschannel',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            '',
            {
                type: null,
                title: null,
                xPath: null,
            },
        );
    },
    onboardingAddFirstPopclip: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.verifyAccount}`;

        dataLakePayloadToTrack(
            'onboarding_add_firstpopclip',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Add Now',
                xPath: '//*[@id="app"]/div[1]/div[2]/div/div[2]/div[3]/div/div[2]/div/div/button',
            },
        );
    },
    onboardingAddPopclipCarousel: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.getStarted}`;

        dataLakePayloadToTrack(
            'onboarding_add_popclipcarousel',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Publish Now',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[1]/div/div/div/div[2]/div[2]/div/div[2]/div/div[4]/div/div[2]/div/div/button',
            },
        );
    },
    browsingViewWelcomeScreen: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'browsing_view_welcomescreen',
            'page_view',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: null,
                title: null,
                xPath: null,
            },
        );
    },
    browsingClickPopclipsManageButton: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'browsing_click_popclipsmanagebutton',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Manage Popclips',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div[2]/div[1]/div[2]/div[2]/button[1]',
            },
        );
    },
    popclipClickCreateButton: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.list}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'popclip_click_createbutton',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Create Popclip',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div/div[2]/div/div/div/div[1]/div/div[2]/button[2]',
            },
        );
    },
    popclipClickEditButton: async (popclipsId: string) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.list}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'popclip_click_editbutton',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            popclipsId,
            entryURL,
            {
                type: 'button',
                title: 'Edit',
                xPath: `popclip_editbtn_${popclipsId}`,
            },
        );
    },
    popclipClickButtonInViewLimitModal: async (
        eventAction: string,
        elementActionStage: string,
        buttonTitle: string,
        offer_product_skucode: string[],
        offer_total_amount: number,
        xPath: string,
    ) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.list}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            eventAction,
            elementActionStage,
            {
                duration: 0,
                location: currentUrl,
                existing_bundle_skucode: shopStore.activePlan?.skuCode,
                existing_addon_skucode: shopStore.getActivePlanExtraAddonSKUs(),
                offer_product_skucode: offer_product_skucode,
                offer_total_amount: offer_total_amount,
                offer_total_currency: 'USD',
                views_consumed_before_reminder: shopStore.featureGatingData
                    ?.popclips?.totalViews
                    ? Number(
                          shopStore.featureGatingData?.popclips?.totalViews.replace(
                              /,/g,
                              '',
                          ),
                      )
                    : 0,
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: buttonTitle,
                xPath: xPath,
            },
        );
    },
});

const dataLakePayloadToTrack = async (
    eventAction: string,
    elementActionStage: string,
    options = {},
    elementID: string,
    entryURL: string,
    elementCTA: {
        type: string | null;
        title: string | null;
        xPath: string | null;
    },
) => {
    const parser = new UAParser();
    const sessionId = await getFingerprint();
    const hashSessionId = sha256(sessionId);
    const { deviceModel, deviceManufacturer } = getDeviceInfo(parser);

    const payloadData = {
        element_action: eventAction,
        element_category: eventAction.split('_')[0] == 'popclip' ? 'popclips' : eventAction.split('_')[0],
        event_timestamp: Date.now(),
        element_action_stage: elementActionStage,
        element_cta_type: elementCTA.type,
        element_cta_title: elementCTA.title,
        element_cta_objectid: elementCTA.xPath,
        entry_url: entryURL,
        element_identifier: elementID,
        shop_id: shopStore.shopifyDomain,
        session_id: `${shopStore.shopifyDomain}_${hashSessionId}`,
        shop_user_id: shopStore.ownerEmail,
        fingerprint_id: hashSessionId,
        event_platform: 'vfs_admin_web',
        event_platform_version: '1.0.0',
        event_context: {
            device_model: deviceModel,
            device_manufacturer: deviceManufacturer,
            operating_system: parser?.getOS()?.name || 'Unknown',
            os_version: parser?.getOS()?.version || 'Unknown',
            language: navigator.language.split('-')[0],
            locale: navigator.language,
            experiment_details: '',
            notifications_enabled: false,
            gclid: null,
            dclid: null,
            gbraid: null,
            wbraid: null,
            fbclid: null,
            twclid: null,
            clid_src: null,
            msclkid: null,
            ttclid: null,
            ...options,
        },
    };
    try {
        fetch(
            `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/productanalytics`,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payloadData),
                redirect: 'follow',
            },
        );
    } catch (error) {
        console.log('Failed to fetch data lake API: ', error);
    }
};

export default analyticsEventStore;
