import { MutableRefObject } from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const openEmbedWindow = (
    themeWindowRef: MutableRefObject<Window | null | undefined>,
    urlToOpen: string,
) => {
    const w = window.outerWidth * 0.6;
    const h = window.outerHeight * 0.6;
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    themeWindowRef.current = window.open(
        urlToOpen,
        '_blank',
        `popup=1,width=${w},height=${h},top=${y},left=${x}`,
    );
};

export const copyTextToClipboard = async (value: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.style.opacity = '0.1';
    textarea.select();

    await navigator.clipboard.writeText(value);
    document.body.removeChild(textarea);
};

export const fetchPost = (url: string, data: any) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

declare global {
    interface Global {
        _fingerprint?: string;
    }
    var _fingerprint: string | undefined;
}

export const getFingerprint = async () => {
    try {
        globalThis._fingerprint = globalThis._fingerprint;

        if (!globalThis._fingerprint) {
            const fp = await FingerprintJS.load();
            globalThis._fingerprint = (await fp.get()).visitorId;

            if (!globalThis._fingerprint) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('Fingerprint not found');
            }
        }

        return globalThis._fingerprint;
    } catch (err) {
        return 'guest';
    }
};

export const getDeviceInfo = (parser) => {
    const ua = window.navigator.userAgent;
    let device;

    if (ua.match(/(iPhone)/)) {
        device = 'iPhone';
    } else if (ua.match(/(iPad)/)) {
        device = 'iPad';
    } else if (ua.match(/(iPod)/)) {
        device = 'iPod';
    } else if (ua.match(/(BlackBerry|BB10)/)) {
        device = 'BlackBerry';
    } else if (ua.match(/(IEMobile)/)) {
        device = 'WindowsMobile';
    } else if (ua.match(/(Android)/)) {
        device = 'Android';
    } else if (ua.match(/(Macintosh)/)) {
        device = 'Macintosh';
    } else if (ua.match(/(Windows)/)) {
        device = 'Windows';
    } else if (ua.match(/(Linux)/)) {
        device = 'Linux';
    }
    return {
        deviceModel: parser.getDevice()?.type
            ? parser.getDevice()?.type
            : device,
        deviceManufacturer: parser.getDevice()?.vendor || 'Unknown',
    };
};

export const isDateBeforeNewUI = (date: Date) =>
    new Date(date.toDateString()) <
    new Date(new Date('2024-12-05T15:44:40.887Z').toDateString());

export const areArraysEqual = (arr1: string[], arr2: string[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
};

export const generateFileFromVideoUrl = async (url: string) => {
    try {
        const videoUrl = new URL(url);
        const videoFileNameArr = videoUrl.pathname.split('/');

        const videoRes = await fetch(url);
        if (!videoRes.ok) throw new Error('Error in fetch video');

        const videoBlobFile = await videoRes.blob();        
        let videoFileName = `${Math.random().toString(36).substring(2,8+2)}_${Date.now()}.${videoBlobFile.type.split('/')[1]}`;
        if (videoFileNameArr.length > 0) {
            videoFileName = videoFileNameArr.pop() || videoFileName;
        }

        return new File([videoBlobFile], videoFileName, {
            type: videoBlobFile.type,
        });
    } catch (error) {
        console.error('Error in generateFileFromVideoUrl', error);
        return;
    }
}

export const countAbbreviation = (count: number|string) => {
    if (!(typeof count === 'number')) {
        return count;
    }
    const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T'];
    if (count < 1000) {
        return count;
    }
    const i = Math.floor(Math.log(count) / Math.log(1000));
    let result = count / 1000 ** i;
    const digits = Math.floor(Math.log10(result)) + 1;
    const decimals = Math.max(0, 2 - digits);
    result = result.toFixed(decimals);
    result = result + COUNT_ABBRS[i];
    return result;
};