import { Icon, InlineStack, ProgressBar } from "@shopify/polaris";
import { customCarouselImg, popclipsCarouselBanner } from "../../../assets/images";
import { ArrowLeftIcon, ArrowRightIcon } from "@shopify/polaris-icons";
import { popclipsRoutes, vfsRoutes } from "@/constants/routes.constants";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { shopStore } from "@/stores";
import { BYPASS_GATING_FOR_DOMAINS } from "@/constants/shopify.constants";

const CardSlides = [
    {
        title1: 'Custom',
        title2: 'Carousel',
        subtitle: (
            <p>
                Pick and choose the Popclips for your custom carousels, sprinkle
                them across your site, and spotlight the Popclips that make your
                products pop!
            </p>
        ),
        image: popclipsCarouselBanner,
        btnLink: vfsRoutes.managePlans,
        btnText: 'Upgrade for only $29.99 / month',
        hideBtn: false,
    },
    {
        title1: 'White-',
        title2: 'Labelling',
        subtitle: (
            <p>
                <span style={{ fontWeight: 'bold' }}>
                    Remove ‘Powered by videeo.live’ Branding{' '}
                </span>
                – Keep the focus where it belongs: on <span style={{fontStyle:'italic'}}>your brand</span>.
            </p>
        ),
        image: customCarouselImg,
        btnLink: vfsRoutes.managePlans,
        btnText: 'Upgrade to Premium',
        hideBtn: false,
    },
];

export default function RecommendedFeaturesCard() {
    const navigate = useNavigate();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const progressInterval = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices('popclips', true);
        const maxPrice = Math.max(...addonPrices);

        if (BYPASS_GATING_FOR_DOMAINS.includes(shopStore.shopifyDomain)) {
            CardSlides[0].btnText = 'Manage Custom Carousel';
            CardSlides[0].btnLink = popclipsRoutes.list;
            CardSlides[1].hideBtn = true;
        } else {
            if (maxPrice >= 29.99) {
                CardSlides[0].btnText = 'Manage Custom Carousel';
                CardSlides[0].btnLink = popclipsRoutes.list;
            }
            if (maxPrice >= 148) {
                CardSlides[1].hideBtn = true;
            }
        }

        
        if (CardSlides.length > 1) {
            progressInterval.current = setInterval(() => {
                setProgressPercentage((prev) => {
                    if (prev >= 100) return 0;
                    return Math.floor(prev + 1.67);
                });
            }, 100);
        }

        return () => {
            if (progressInterval.current) {
                clearInterval(progressInterval.current);
            }
        };
    }, []);

    useEffect(() => {
        if (CardSlides.length > 1 && progressPercentage > 90) {
            resetIntervalAndChangeSlide(
                currentSlide === CardSlides.length - 1 ? 0 : currentSlide + 1,
            );
        }
    }, [progressPercentage]);

    const resetIntervalAndChangeSlide = (changeToSlide: number) => {
        if (progressInterval.current) {
            clearInterval(progressInterval.current);
        }
        setProgressPercentage(0);
        
        setTimeout(() => {
            setCurrentSlide(changeToSlide);

            progressInterval.current = setInterval(() => {
                setProgressPercentage((prev) => {
                    if (prev >= 100) return 0;
                    return Math.floor(prev + 1.67);
                });
            }, 100);
        }, 0);
    }

    return (
        <div className="recommended-features-card">
            <div className="card-badge">Recommended Feature</div>
            <div className="title-container">
                <p className="card-title">
                    <span>{CardSlides[currentSlide].title1}</span>
                    <span>{CardSlides[currentSlide].title2}</span>
                </p>
            </div>
            <div className="card-content">
                {CardSlides[currentSlide].subtitle}
                {!CardSlides[currentSlide].hideBtn && (
                    <button
                        className="unlock-feature-button"
                        onClick={() =>
                            navigate(CardSlides[currentSlide].btnLink)
                        }
                    >
                        {CardSlides[currentSlide].btnText}
                    </button>
                )}
            </div>
            <div className="card-image">
                <img
                    src={CardSlides[currentSlide].image}
                    alt="Custom Carousel"
                    style={{ width: '228px', height: '160px' }}
                />
                {CardSlides.length > 1 && (
                    <InlineStack gap="600" align="center" blockAlign="center">
                        <div
                            className="paginate-btn"
                            onClick={() =>
                                resetIntervalAndChangeSlide(
                                    currentSlide === 0
                                        ? CardSlides.length - 1
                                        : currentSlide - 1,
                                )
                            }
                        >
                            <Icon source={ArrowLeftIcon} tone="magic" />
                        </div>
                        <div
                            className="paginate-btn"
                            onClick={() =>
                                resetIntervalAndChangeSlide(
                                    currentSlide === CardSlides.length - 1
                                        ? 0
                                        : currentSlide + 1,
                                )
                            }
                        >
                            <Icon source={ArrowRightIcon} tone="magic" />
                        </div>
                    </InlineStack>
                )}
            </div>
            {CardSlides.length > 1 && (
                <div className="progress-bar-container">
                    <ProgressBar progress={progressPercentage} size="medium" />
                </div>
            )}
        </div>
    );
};
