import { Text, Banner, Box, Link, InlineStack } from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import { buildMobileAppStore } from '@/stores';

interface AppProgressCardPropsTypes {
    bannerTone: 'info' | 'success' | 'warning';
    status: string;
    description: string;
    link: boolean;
}

export const AppProgressCard = ({
    bannerTone,
    status,
    description,
    link = false,
}: AppProgressCardPropsTypes) => {
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });
    const { isAppRequestSubmitted } = buildMobileAppSnapshot;

    return (
        isAppRequestSubmitted && (
            <Banner title={status} tone={bannerTone}>
                <Box width="58rem">
                    {link ? (
                        <InlineStack gap="100">
                            <Text as="p">{description}</Text>
                            <Link url="https://appstoreconnect.apple.com/business">
                                Click here to update now
                            </Link>
                        </InlineStack>
                    ) : (
                        <Text as="p">{description}</Text>
                    )}
                </Box>
            </Banner>
        )
    );
};
