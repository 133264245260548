import { BlockStack, Box, InlineGrid } from '@shopify/polaris';
import HelpCenterResourceCard from '@/components/common/HelpCenterResourceCard';
import HelpCenterComponent from '@/components/common/HelpCenterComponent';
import { BaseLayout } from '../v2/pages/Layout/BaseLayout';

const supportResourceCards = [
    {
        cardTitle: 'Help Center',
        cardDescription:
            'Find step by step guides on videeo features and functions',
        btnLabel: 'Get Help Now',
        externalLink: 'https://help.videeo.live/hc/en-us',
    },
    {
        cardTitle: 'Release Notes',
        cardDescription:
            'Stay up to date on the latest feature releases and bug fixes',
        btnLabel: 'View Release Notes',
        externalLink:
            'https://help.videeo.live/hc/en-us/articles/27276114391188-Videeo-Release-Notes',
    },
    {
        cardTitle: 'Youtube',
        cardDescription: 'Find how-to and support videos',
        btnLabel: 'View Our Channel',
        externalLink: 'https://www.youtube.com/@VideeoforShopifyCS',
    },
    {
        cardTitle: 'Webinar',
        cardDescription:
            'Learn how to get the most out of your Videeo product by attending our webinars',
        btnLabel: 'Sign Up',
        externalLink:
            'https://eventhub.goldcast.io/?eventHubId=12018e5b-dcf4-468a-b1d8-30b0f794c81f',
    },
];

const SupportResourcesPage = () => {
    return (
        <BaseLayout title="Videeo - Help Center">
            <Box minHeight='40rem'>
                <BlockStack inlineAlign="center">
                    <Box width="100%">
                        <InlineGrid
                            columns={shopify.environment.mobile ? 2 : 4}
                            gap="400"
                        >
                            {supportResourceCards.map(
                                (
                                    {
                                        cardTitle,
                                        cardDescription,
                                        btnLabel,
                                        externalLink,
                                    },
                                    index,
                                ) => (
                                    <HelpCenterResourceCard
                                        key={index}
                                        cardTitle={cardTitle}
                                        cardDescription={cardDescription}
                                        btnLabel={btnLabel}
                                        externalLink={externalLink}
                                    />
                                ),
                            )}
                        </InlineGrid>
                    </Box>
                    {<HelpCenterComponent />}
                </BlockStack>
            </Box>
        </BaseLayout>
    );
};

export default SupportResourcesPage;
