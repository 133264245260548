import { analyticsEventStore, shopStore } from "@/stores";
import { BlockStack, Box, Icon, InlineStack, Link, Text } from "@shopify/polaris";
import { ArrowDiagonalIcon, CaretDownIcon, CaretUpIcon, CheckCircleIcon, ClockIcon } from "@shopify/polaris-icons";

type WizardStepProps = {
    stepExpanded: boolean;
    isCompleted: boolean;
};

export default function WizardStep2({
    stepExpanded,
    isCompleted,
}: WizardStepProps) {
    const openPublishWindow = () => {
        analyticsEventStore.onboardingClickVideoSalesChannel();
        window.open(
            `https://admin.shopify.com/store/${shopStore.shopHandle}/products?selectedView=all&status=ACTIVE`,
        );
    }
    
    return (
        <Box
            background={
                !isCompleted && stepExpanded ? 'bg-fill-active' : 'bg-fill'
            }
            padding="300"
            borderRadius="200"
        >
            <InlineStack align="space-between">
                <InlineStack gap="300">
                    <Icon source={CheckCircleIcon} />
                    <Text as="p" variant="bodyLg">
                        Publish products to Videeo sales channel
                    </Text>
                </InlineStack>
                {!isCompleted && (
                    <InlineStack gap="500">
                        <InlineStack gap="100">
                            <Icon source={ClockIcon} />
                            <p>2 Mins</p>
                        </InlineStack>
                        <Icon
                            source={stepExpanded ? CaretUpIcon : CaretDownIcon}
                        />
                    </InlineStack>
                )}
            </InlineStack>
            {!isCompleted && stepExpanded && (
                <Box paddingBlockStart="400">
                    <BlockStack gap="400">
                        <iframe
                            style={{ width: '100%', height: '320px' }}
                            src="https://www.youtube.com/embed/qNCYjiVb00U?si=zukM5Y8xNX4-NcmC"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>
                        <Text as="p" tone="subdued">
                            Products must be included in the Videeo sales
                            channel to be tagged in Popclips{' '}
                            <Link
                                url="https://help.videeo.live/hc/en-us/articles/31670665636756-Publish-Products-to-the-Videeo-Sales-Channel"
                                target="_blank"
                            >
                                <Text as="span" fontWeight="bold" tone="magic">
                                    Learn more
                                </Text>
                            </Link>
                        </Text>
                        <Box
                            background="bg-fill"
                            padding="200"
                            borderRadius="200"
                        >
                            <Text as="p">
                                Once you successfully publish your products,
                                please refresh the screen manually to proceed to
                                the next step
                            </Text>
                        </Box>
                        <InlineStack align="start">
                            <button
                                className="onboarding-wizard-modal__step-cta"
                                onClick={openPublishWindow}
                            >
                                Publish Now <Icon source={ArrowDiagonalIcon} />
                            </button>
                        </InlineStack>
                    </BlockStack>
                </Box>
            )}
        </Box>
    );
}