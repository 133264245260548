import {
    BlockStack,
    Box,
    Card,
    Grid,
    SkeletonBodyText,
} from '@shopify/polaris';

const DashboardLoader = () => {
    return (
        <Box paddingBlock="800">
            <Grid>
                <Grid.Cell columnSpan={{ xs: 4, sm: 4, md: 4, lg: 8, xl: 8 }}>
                    <Card>
                        <BlockStack gap="400">
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                        </BlockStack>
                    </Card>
                </Grid.Cell>

                <Grid.Cell columnSpan={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
                    <Card>
                        <BlockStack gap="400">
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                        </BlockStack>
                    </Card>
                </Grid.Cell>

                <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    <BlockStack gap="400">
                        <Card>
                            <BlockStack gap="400">
                                <SkeletonBodyText />
                                <SkeletonBodyText />
                            </BlockStack>
                        </Card>
                        <Card>
                            <BlockStack gap="400">
                                <SkeletonBodyText />
                                <SkeletonBodyText />
                            </BlockStack>
                        </Card>
                    </BlockStack>
                </Grid.Cell>

                <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'grid',
                        }}
                    >
                        <Card>
                            <BlockStack gap="400">
                                <SkeletonBodyText />
                                <SkeletonBodyText />
                                <SkeletonBodyText />
                                <SkeletonBodyText />
                            </BlockStack>
                        </Card>
                    </div>
                </Grid.Cell>

                <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    <Card>
                        <BlockStack gap="400">
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                        </BlockStack>
                    </Card>
                </Grid.Cell>

                <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    <Card>
                        <BlockStack gap="400">
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                        </BlockStack>
                    </Card>
                </Grid.Cell>
            </Grid>
        </Box>
    );
};

export default DashboardLoader;
