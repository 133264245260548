import { PropsWithChildren, useEffect, useState } from 'react';
import { AlertModal } from './AlertModal';
import { BlockStack, Box, Icon, InlineStack, Text } from '@shopify/polaris';
import { CheckIcon } from '@shopify/polaris-icons';
import upgradePlan from '../../assets/images/upgradePlan.svg';
import { useNavigate } from 'react-router-dom';
import { vfsRoutes } from '@/constants/routes.constants';
import VfsModal from '../../v2/Components/VfsModal';
import { shopStore } from '@/stores';
import { POPCLIPS } from '@/constants/popclipsFeature.constants';

type PlanUpgradeAlertModalProps = {
    showPlanUpgradeModal?: boolean;
    handleCloseModal: () => void;
    type?: string;
    isRemoveWatermarkBanner?: boolean;
};

type removeWaterMarkPlanListTypes = {
    icon: string;
    header: string;
    text: string;
};

const featureList = [
    "Remove 'powered by video.live' branding",
    'Create stunning, collection-based custom video carousels to showcase tailored content',
    "Enable visitors to 'like' Popclips",
    "Display 'likes count' directly on videos to drive engagement and social proof",
    'Control like counter & custom carousels',
    'AI-driven auto placement of videos on feed',
];

const basicPlanModalList = [
    'Pick and choose videos to create custom video carousels',
    'Publish custom video carousels anywhere on your website',
    'Manage your custom video carousels',
    'Import your videos directly from Instagram',
    "Display video 'view count' directly on video",
    'Control view counter & carousel settings',
];

const removeWaterMarkFreePlanList = [
    {
        icon: '🛠️',
        header: "No More 'Powered by Videeo.live – ",
        text: 'Your site, your branding.',
    },
    {
        icon: '📂',
        header: 'Custom Carousels by Collection– ',
        text: 'Why spend time sorting videos manually when we can do the work for you? Automatically pull clips by product category or collection and wow your visitors with next-level Popclips.',
    },
    {
        icon: '👍',
        header: 'Show Likes and View Counts on Popclips – ',
        text: 'Highlight the love and build instant trust.',
    },
    {
        icon: '📱',
        header: 'Instagram and TikTok Video Import – ',
        text: 'Upload your top videos and user-generated content effortlessly.',
    },
];

const removeWaterMarkBasicPlanList = [
    {
        icon: '🛠️',
        header: "No More 'Powered by Videeo.live – ",
        text: 'Your site, your branding.',
    },
    {
        icon: '📂',
        header: 'Custom Carousels by Collection– ',
        text: 'Why spend time sorting videos manually when we can do the work for you? Automatically pull clips by product category or collection and wow your visitors with next-level Popclips.',
    },
    {
        icon: '👍',
        header: 'Show Likes Counts on Popclips – ',
        text: 'Highlight the love and build instant trust.',
    },
];

export const PlanUpgradeAlertModal = ({
    showPlanUpgradeModal = false,
    handleCloseModal,
    type = '',
    isRemoveWatermarkBanner = false,
}: PropsWithChildren<PlanUpgradeAlertModalProps>) => {
    const navigate = useNavigate();
    const [removeWaterMarkPlanList, setRemoveWaterMarkPlanList] = useState<
        removeWaterMarkPlanListTypes[]
    >([]);

    const handleUpgradeNow = () => {
        // analyticsEventStore.popclipClickButtonInViewLimitModal(
        //     'account_react_freeuserpopclipspopupoffer',
        //     'popup_offer_reaction',
        //     'Upgrade just for $29.99/month',
        //     ['addon-popclips-basic'],
        //     29.99,
        //     '//*[@id="modal-content-8885451e-38a1-4196-835b-40f3efb46c4e"]/div/div/div/div/button[2]',
        // );
        navigate(vfsRoutes.managePlans);
    };

    const closeModal = () => {
        handleCloseModal();
        // analyticsEventStore.popclipClickButtonInViewLimitModal(
        //     'account_react_freeuserpopclipspopupoffer',
        //     'popup_offer_reaction',
        //     'Continue with Free Plan',
        //     ['addon-popclips-basic'],
        //     29.99,
        //     '//*[@id="modal-content-8885451e-38a1-4196-835b-40f3efb46c4e"]/div/div/div/div/button[1]',
        // );
    }

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(POPCLIPS, true);
        const maxPrice = Math.max(...addonPrices);
        if (maxPrice < 29.99) {
            setRemoveWaterMarkPlanList(removeWaterMarkFreePlanList);
        } else if (maxPrice == 29.99) {
            setRemoveWaterMarkPlanList(removeWaterMarkBasicPlanList);
        }
    });

    return isRemoveWatermarkBanner ? (
        <VfsModal
            title=""
            primaryActionText={
                isRemoveWatermarkBanner ? 'Upgrade to Premium' : 'Upgrade Now'
            }
            secondaryActionText="Cancel"
            primaryAction={handleUpgradeNow}
            secondaryAction={() => closeModal()}
            onCloseModal={() => closeModal()}
            crossIcon={true}
            modalWidth="32rem"
        >
            <BlockStack gap="400">
                <Box padding="200">
                    <BlockStack inlineAlign="center" gap="200">
                        <img
                            alt=""
                            width="90px"
                            height="120px"
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                            src={upgradePlan}
                        />
                        <Text variant="headingLg" as="h5" alignment="center">
                            Take Your Popclips to the Next Level with the
                            Premium Plan 🚀
                        </Text>
                        <Text as="p" alignment="center" variant="bodyLg">
                            Remove "Powered by videeo.live" Branding – Keep the
                            focus where it belongs: on your brand!
                        </Text>
                    </BlockStack>
                </Box>
                <Box
                    borderRadius="200"
                    background="bg-fill-info-secondary"
                    padding="200"
                >
                    <Box paddingBlock="200">
                        <Text as="p" fontWeight="medium" variant="bodyMd">
                            Upgrade today and level up with these game-changing
                            features:
                        </Text>
                    </Box>
                    {removeWaterMarkPlanList?.length &&
                        removeWaterMarkPlanList.map((item, index) => (
                            <Box paddingBlockEnd={'100'} key={index}>
                                <InlineStack
                                    key={index}
                                    gap="200"
                                    blockAlign="start"
                                    wrap={false}
                                >
                                    <Text as="p" variant="bodyMd" key={index}>
                                        {item.icon}
                                    </Text>
                                    <InlineStack
                                        key={index}
                                        gap="100"
                                        wrap={true}
                                        blockAlign="center"
                                    >
                                        <Text
                                            as="p"
                                            fontWeight="medium"
                                            variant="bodyMd"
                                            key={index}
                                        >
                                            {item.header}
                                            <Text
                                                fontWeight="regular"
                                                variant="bodyMd"
                                                as="span"
                                                key={index}
                                            >
                                                {item.text}
                                            </Text>
                                        </Text>
                                    </InlineStack>
                                </InlineStack>
                            </Box>
                        ))}
                </Box>
            </BlockStack>
        </VfsModal>
    ) : (
        <AlertModal
            title="Upgrade To Access Feature"
            positiveBtnLabel="Upgrade Now"
            negativeBtnLabel="Cancel"
            positiveAction={handleUpgradeNow}
            negativeAction={() => handleCloseModal()}
            shouldShowModal={showPlanUpgradeModal}
            closeModal={() => handleCloseModal()}
            size="small"
        >
            <Box>
                <Box padding="200">
                    <BlockStack inlineAlign="center" gap="100">
                        <img
                            alt=""
                            width="90px"
                            height="120px"
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                            src={upgradePlan}
                        />
                        <Text variant="headingLg" as="h5" alignment="center">
                            Uh-oh! You currently don’t have access.Unlock More
                            Power with our plans!
                        </Text>
                        <Text as="p" alignment="center">
                            To access Select by Collection in Creating Custom
                            Carousel and other powerful tools designed to boost
                            your reach and impact, you’ll need to upgrade to a
                            higher plan.
                        </Text>
                    </BlockStack>
                </Box>
                <Box
                    borderRadius="200"
                    background="bg-fill-info-secondary"
                    padding="200"
                >
                    {' '}
                    {type === 'POPCLIPS_BASIC_PLAN' && (
                        <Box paddingBlockEnd="200">
                            <Text as="h6" fontWeight="bold">
                                Everything in Free, plus:
                            </Text>
                        </Box>
                    )}
                    {type === 'POPCLIPS_BASIC_PLAN'
                        ? basicPlanModalList.map((item) => (
                              <InlineStack key={item} gap="100" wrap={false}>
                                  <Box>
                                      <Icon source={CheckIcon} tone="info" />
                                  </Box>
                                  <Text as="p" key={item}>
                                      {item}
                                  </Text>
                              </InlineStack>
                          ))
                        : featureList.map((item) => (
                              <InlineStack key={item} gap="100" wrap={false}>
                                  <Box>
                                      <Icon source={CheckIcon} tone="info" />
                                  </Box>
                                  <Text as="p" key={item}>
                                      {item}
                                  </Text>
                              </InlineStack>
                          ))}
                </Box>
            </Box>
        </AlertModal>
    );
};
