import {
    BlockStack,
    Box,
    Button,
    ButtonGroup,
    InlineStack,
    Spinner,
    Text,
} from '@shopify/polaris';
import Plot from 'react-plotly.js';
import DashboardHeaderSection from './DashboardHeaderSection';
import DashboardIndicatorTag from './DashboardIndicatorTag';
import { useEffect, useState } from 'react';
import VfsModal from '../../v2/Components/VfsModal';
import { commaSeprateValue } from '@/utils/MoneyFormatter';
import { CONFIG } from './chartConstantConfig';

interface DashboardTotalSalesChartPropsTypes {
    title: string;
    directSaleValue: number;
    indirectSaleValue: number;
    influenced: number;
    salesDirectAmount: number[];
    salesIndirectAmount: number[];
    salesInfluencedAmount: number[];
    labels: string[];
    isLoading: boolean;
    chartData: any;
}

const DashboardTotalSalesChart = ({
    title,
    directSaleValue,
    indirectSaleValue,
    influenced,
    salesDirectAmount,
    salesIndirectAmount,
    salesInfluencedAmount,
    labels,
    isLoading,
    chartData,
}: DashboardTotalSalesChartPropsTypes) => {
    const [isExpand, setIsExpand] = useState(false);
    const [isDirectSaleValue, setIsDirectSaleValue] = useState('DIRECT');
    const [growthInValue, setGrowthInValue] = useState(
        chartData?.directPercentage || 0,
    );
    const [lineChartData, setLineChartData] = useState({
        x: [],
        y: [],
        type: 'scatter',
        mode: 'lines',
        line: {
            color: '#FC5452',
            width: 3,
            shape: 'spline',
            smoothing: 0.3,
        },
        hovertemplate: '%{y}<extra></extra>',
    });
    const [lineShadowChartData, setLineShadowChartData] = useState({
        x: [],
        y: [],
        type: 'scatter',
        mode: 'none',
        fill: 'tozeroy',
        fillcolor: 'rgba(252, 84, 82, 0.1)',
        hoverinfo: 'skip',
    });

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    const handleToggleButtonClick = (event, value: string) => {
        event.stopPropagation();
        setIsDirectSaleValue(value);

        switch (value) {
            case 'DIRECT':
                setGrowthInValue(chartData?.directPercentage || 0);
                break;
            case 'INDIRECT':
                setGrowthInValue(chartData?.indirectPercentage || 0);
                break;
            case 'INFLUENCED':
                setGrowthInValue(chartData?.influencedPercentage || 0);
                break;

            default:
                break;
        }
    };

    const SaleValueWithToggleButton = () => {
        return (
            <InlineStack align="space-between" blockAlign="center">
                {isDirectSaleValue == 'DIRECT' && (
                    <Text variant="heading2xl" as="h3">
                        {commaSeprateValue(directSaleValue)} USD
                    </Text>
                )}
                {isDirectSaleValue == 'INDIRECT' && (
                    <Text variant="heading2xl" as="h3">
                        {commaSeprateValue(indirectSaleValue)} USD
                    </Text>
                )}
                {isDirectSaleValue == 'INFLUENCED' && (
                    <Text variant="heading2xl" as="h3">
                        {commaSeprateValue(influenced)} USD
                    </Text>
                )}
                <ButtonGroup variant="segmented">
                    <Button
                        pressed={isDirectSaleValue == 'DIRECT'}
                        onClick={(event) =>
                            handleToggleButtonClick(event, 'DIRECT')
                        }
                    >
                        Direct
                    </Button>
                    <Button
                        pressed={isDirectSaleValue == 'INDIRECT'}
                        onClick={(event) =>
                            handleToggleButtonClick(event, 'INDIRECT')
                        }
                    >
                        Indirect
                    </Button>
                    <Button
                        pressed={isDirectSaleValue == 'INFLUENCED'}
                        onClick={(event) =>
                            handleToggleButtonClick(event, 'INFLUENCED')
                        }
                    >
                        Influenced
                    </Button>
                </ButtonGroup>
            </InlineStack>
        );
    };

    const DrawSalesChart = () => {
        return (
            <Plot
                data={[
                    // Shadow area
                    lineShadowChartData,
                    // Line
                    lineChartData,
                ]}
                layout={{
                    xaxis: {
                        title: 'Weeks',
                    },
                    yaxis: {
                        visible: true,
                        showgrid: false,
                        ticks: '', // Removes ticks
                        showticklabels: false,
                        title: 'Sales',
                        hoverformat: '.f',
                    },
                    showlegend: false,
                    autosize: isExpand,
                    height: 210,
                    width: isExpand ? '' : 460,
                    margin: { t: 20, l: 30, r: 30, b: 40 },
                    plot_bgcolor: 'rgba(0,0,0,0)', // Transparent background for gradient
                    dragmode: 'pan',
                }}
                config={{
                    ...CONFIG,
                    displayModeBar: isExpand, // Hides the mode bar for a cleaner UI
                    staticPlot: !isExpand,
                }}
            />
        );
    };

    const setChartData = () => {
        if (isDirectSaleValue == 'DIRECT') {
            setLineChartData({
                ...lineChartData,
                x: labels,
                y: salesDirectAmount,
            });
            setLineShadowChartData({
                ...lineShadowChartData,
                x: labels,
                y: salesDirectAmount,
            });
        }
        if (isDirectSaleValue == 'INDIRECT') {
            setLineChartData({
                ...lineChartData,
                x: labels,
                y: salesIndirectAmount,
            });
            setLineShadowChartData({
                ...lineShadowChartData,
                x: labels,
                y: salesIndirectAmount,
            });
        }
        if (isDirectSaleValue == 'INFLUENCED') {
            setLineChartData({
                ...lineChartData,
                x: labels,
                y: salesInfluencedAmount,
            });
            setLineShadowChartData({
                ...lineShadowChartData,
                x: labels,
                y: salesInfluencedAmount,
            });
        }
    };
    useEffect(() => {
        setChartData();
    }, [isLoading, isDirectSaleValue]);
    return (
        <div
            style={{
                display: 'grid',
            }}
        >
            <Box
                borderWidth="025"
                borderColor="border"
                borderRadius="500"
                padding="600"
                background="bg-fill"
                shadow="200"
            >
                <DashboardHeaderSection
                    title={title}
                    showResizeButton={true}
                    isExpand={isExpand}
                    handleResizeChart={handleResizeChart}
                />
                <SaleValueWithToggleButton />
                <DashboardIndicatorTag
                    growthInValue={growthInValue}
                    isMonth={true}
                />
                <Box paddingInline="025">
                    {isLoading ? (
                        <Box paddingBlock="1600">
                            <InlineStack align="center" blockAlign="center">
                                <Spinner />
                            </InlineStack>
                        </Box>
                    ) : (
                        <DrawSalesChart />
                    )}
                </Box>
            </Box>
            {isExpand && (
                <VfsModal
                    onCloseModal={handleResizeChart}
                    title={title}
                    modalWidth="90%"
                >
                    <Box width="100%">
                        <BlockStack gap="400" align="start">
                            <SaleValueWithToggleButton />
                            <DashboardIndicatorTag
                                growthInValue={growthInValue}
                                isMonth={true}
                            />
                            {isLoading ? (
                                <InlineStack align="center" blockAlign="center">
                                    <Spinner />
                                </InlineStack>
                            ) : (
                                <DrawSalesChart />
                            )}
                        </BlockStack>
                    </Box>
                </VfsModal>
            )}
        </div>
    );
};

export default DashboardTotalSalesChart;
