import { useCallback } from 'react';
import { useVFSAuthFetch } from './useAuthenticatedFetch';
import { buildMobileAppStore, shopStore } from '@/stores';

export const useSendAppRequest = () => {
    const fetchVFSAuthFetch = useVFSAuthFetch();

    const sendAppRequest = useCallback(async () => {
        const {
            appleDevTeamId,
            companyName,
            desiredAppName,
            desiredAppNameSecond,
            desiredAppNameThird,
            appLogoUrl,
            launchScreenImageUrl,
            supportURL,
        } = buildMobileAppStore;
        const response = await fetch('/api/shopify/shop/mobile/app-request', {
            method: 'POST',
            body: JSON.stringify({
                request_type: 'VFSNewApp',
                appleDevTeamId: appleDevTeamId,
                appleCompanyName: companyName,
                desiredAppName: desiredAppName,
                altAppNameOne: desiredAppNameSecond,
                altAppNameTwo: desiredAppNameThird,
                iconS3Url: appLogoUrl,
                launchS3Url: launchScreenImageUrl,
                supportUrl: supportURL,
            }),
        });
        const resp = await response.json();
        const data = resp?.data;
        if(data?.id && data?.sk_identifier){
            addSKIdentifier(data.id, data.sk_identifier);
        }
    }, [fetchVFSAuthFetch]);
    return { sendAppRequest };
};

const addSKIdentifier = async (requestId: string,skIdentifier: string) => {
    await fetch('/api/shopify/shop/app-request', {
        method: 'POST',
        body: JSON.stringify({
            requestId: requestId,
            skIdentifier: skIdentifier,
        }),
    });
    await shopStore.getShop();
}