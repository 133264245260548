import {
    shopCollectionStore,
} from '@/stores';
import { useCallback } from 'react';
import shopStore from '@/stores/shopStore';
import { SHOPIFY_SF_API_VERSION } from '@/constants/shopify.constants';

export const useStorefrontGraphQL = () => {
    const makeQuery = useCallback(
        async <T>(body: {
            query: string;
            variables?: Record<string, string | number | boolean>;
        }): T => {
            const { shopifyDomain } = shopStore;
            if (!shopifyDomain) {
                throw new Error('no shopify domain found');
            }
            try {
                shopCollectionStore.areCollectionProductsLoading = true;
                const res = await fetch(
                    `https://${shopStore.shopifyDomain}/api/${SHOPIFY_SF_API_VERSION}/graphql.json`,
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'X-Shopify-Storefront-Access-Token':
                                shopStore.storefrontAccessToken,
                        },
                        body: JSON.stringify(body),
                    },
                );
                if (res.ok) {
                    const { data } = (await res.json()) as T;
                    return data;
                }
                throw new Error(res);
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        [shopStore.shopifyDomain],
    );
    return { makeQuery };
};
