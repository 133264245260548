import { proxy } from 'valtio';
import type { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';
import { getSanitizedPhoneNumber } from '@/utils/sanitize';
import shopStore from './shopStore';
const utf8mb4Regex = /[\u{10000}-\u{10FFFF}]/u;

export type ShopifyProductsFormated = {
    id: string;
    title: string;
    description: string;
    handle: string;
    amount: string;
    currencyCode: string;
    imgSrc: string;
    imgAlt: string;
    createdAt?: string;
};

type ShopifyProductPagination = {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    nextCursor: string;
    prevCursor: string;
};

type VIDEO_LIVE_SELLING_STORE = {
    userAuth: import('firebase/auth').ConfirmationResult | null;
    countryCode: string;
    phoneNumber: string;
    isPhoneNumberInvalid: boolean;
    validatePhoneNumber: () => boolean;
    email: string;
    firstName: string;
    lastName: string;
    confirmationCode: string;
    confirmationCodeErr: string;
    clipSourceType: 'instagram' | 'tiktok' | 'device';
    clipSourceUrl: string;
    clipSourceUrlErr: string;
    clipTitle: string;
    isFeatured: boolean;
    clipTitleErr: string;
    videoUrl: string;
    videoUrlErr: string;
    setConfiramtionCode: (value: string) => void;
    hasConfirmationCodeErr: () => void;
    setClipTitle: (value: string) => void;
    hasClipTitleErr: () => void;
    hasSourceUrlErr: () => void;
    setVideoUrl: (value: string) => void;
    hasVideoUrlErr: () => void;
    userAuthDetails?: import('firebase/auth').UserCredential;
    sellerID?: string;
    isClipExcluded: boolean;
    areProductsLoading: boolean;
    shopifySelectedProducts: ShopifyProductsFormated[];
    uploadedPopClipData: {
        clipId: string;
        clipMetaIdentifier: string;
        clipMetaData: object;
        uploadVideoUrl: string;
    };
    dropzoneFile: FormData | null;
    resetCreatePopClip: () => void;
    savingPopclip: boolean;
    editClipData: ListMediaByIdResponse | null;
    shopifyProductPagination: ShopifyProductPagination;
    gridCurrentPage: number;
    gridLastEvalKey: {
        [key: string]:
            | {
                  id: string;
                  fileTypeAndIdKey: string;
                  metaIdentifier: string;
              }
            | undefined;
    };
    importInstaVideo: () => Promise<any>;
    importTiktokVideo: () => Promise<any>;
    saveFeaturePopclips: (popclip_id: string) => Promise<any>;
    isFeaturedPopclip: (popclip_id: string) => boolean;
};

const videoLiveSellingStore: VIDEO_LIVE_SELLING_STORE =
    proxy<VIDEO_LIVE_SELLING_STORE>({
        countryCode: '+1',
        phoneNumber: '',
        isPhoneNumberInvalid: false,
        validatePhoneNumber: () => {
            const countryCodePattern = /^(\+?\d{1,3}|\d{1,4})$/;
            const phonePattern = /^(?:[0-9\-\\(\\)\\\/.]\s?){4,15}[0-9]{1}$/;
            const eDot164Pattern = /^\+[1-9]\d{1,14}$/;

            let isValid = countryCodePattern.test(
                videoLiveSellingStore.countryCode,
            );
            if (!isValid) {
                videoLiveSellingStore.isPhoneNumberInvalid = true;
                return false;
            }

            isValid = phonePattern.test(videoLiveSellingStore.phoneNumber);
            if (!isValid) {
                videoLiveSellingStore.isPhoneNumberInvalid = true;
                return false;
            }

            const phoneNumber = `${videoLiveSellingStore.countryCode}${videoLiveSellingStore.phoneNumber}`;
            const sanitizedPhoneNumber = getSanitizedPhoneNumber(phoneNumber);
            isValid = eDot164Pattern.test(sanitizedPhoneNumber);
            if (!isValid) {
                videoLiveSellingStore.isPhoneNumberInvalid = true;
                return false;
            }

            videoLiveSellingStore.isPhoneNumberInvalid = false;
            return true;
        },
        confirmationCode: '',
        setConfiramtionCode: (code: string) => {
            videoLiveSellingStore.confirmationCode = code;
        },
        confirmationCodeErr: '',
        hasConfirmationCodeErr: () => {
            const isValid =
                videoLiveSellingStore.confirmationCode.length > 0 &&
                videoLiveSellingStore.confirmationCode.length === 6 &&
                (videoLiveSellingStore.confirmationCode.match(/\d/g)?.length ??
                    0) >= 6 &&
                !utf8mb4Regex.test(videoLiveSellingStore.confirmationCode);
            videoLiveSellingStore.confirmationCodeErr = isValid
                ? ''
                : 'Please enter a valid code';
        },
        clipSourceType: 'device',
        clipSourceUrl: '',
        clipSourceUrlErr: '',
        clipTitle: '',
        isFeatured: false,
        setClipTitle: (title: string) => {
            videoLiveSellingStore.clipTitle = title;
        },
        clipTitleErr: '',
        hasClipTitleErr: () => {
            videoLiveSellingStore.clipTitleErr =
                videoLiveSellingStore.clipTitle.trim() === ''
                    ? 'This is a required field'
                    : '';
        },
        hasSourceUrlErr: () => {
            const sourceUrl = videoLiveSellingStore.clipSourceUrl.trim() as string;
            videoLiveSellingStore.clipSourceUrlErr = '';

            if (!sourceUrl || sourceUrl === '') {
                videoLiveSellingStore.clipSourceUrlErr = 'This is a required field';
            }

            if (
                videoLiveSellingStore.clipSourceType === 'tiktok' &&
                !(/\d/.test(sourceUrl.split('/').pop()))
            ) {
                videoLiveSellingStore.clipSourceUrlErr = 'This is not a valid TikTok reel URL';
            }
            
            if (
                videoLiveSellingStore.clipSourceType === 'instagram' &&
                !sourceUrl.startsWith('https://www.instagram.com/reel/')
            ) {
                videoLiveSellingStore.clipSourceUrlErr = 'The link must start with https://www.instagram.com/reel/';
            }
        },
        videoUrl: '',
        setVideoUrl: (url: string) => {
            videoLiveSellingStore.videoUrl = url;

            if (videoLiveSellingStore.clipSourceType === 'device') {
                videoLiveSellingStore.clipSourceUrl = '';
            }
        },
        videoUrlErr: '',
        hasVideoUrlErr: () => {
            videoLiveSellingStore.videoUrlErr =
                videoLiveSellingStore.videoUrl.trim() === ''
                    ? 'This is a required field'
                    : '';
        },
        userAuth: null,
        userAuthDetails: undefined,
        isClipExcluded: false,
        areProductsLoading: false,
        shopifySelectedProducts: [] as ShopifyProductsFormated[],
        email: '',
        firstName: '',
        lastName: '',
        uploadedPopClipData: {
            clipId: '',
            clipMetaIdentifier: '',
            clipMetaData: {},
            uploadVideoUrl: '',
        },
        dropzoneFile: null,
        resetCreatePopClip: () => {
            videoLiveSellingStore.shopifySelectedProducts = [];
            videoLiveSellingStore.clipTitle = '';
            videoLiveSellingStore.videoUrl = '';
            videoLiveSellingStore.clipSourceUrl = '';
            videoLiveSellingStore.clipSourceType = 'device';
            videoLiveSellingStore.clipSourceUrlErr = '';
            videoLiveSellingStore.dropzoneFile = null;
            videoLiveSellingStore.editClipData = null;
            videoLiveSellingStore.clipTitleErr = '';
            videoLiveSellingStore.videoUrlErr = '';
            videoLiveSellingStore.isClipExcluded = false;
        },
        editClipData: null,
        savingPopclip: false,
        shopifyProductPagination: {} as ShopifyProductPagination,
        gridCurrentPage: 1,
        gridLastEvalKey: {},
        importInstaVideo: async () => {
            try {
                const result = await fetch(`${shopStore.popclipsServiceApi}/instagram/post`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ postUrl: videoLiveSellingStore.clipSourceUrl }),
                });

                if (!result.ok) {
                    throw new Error('Failed to import video');
                }

                const { data } = await result.json();
                return data;
            } catch (error) {
                console.error(error);
                return null;
            }
        },
        importTiktokVideo: async () => {
            try {
                const result = await fetch(`${shopStore.popclipsServiceApi}/tiktok/post`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ postUrl: videoLiveSellingStore.clipSourceUrl }),
                });

                if (!result.ok) {
                    throw new Error('Failed to import video');
                }

                const { data } = await result.json();
                return data;
            } catch (error) {
                console.error(error);
                return null;
            }
        },
        saveFeaturePopclips: async (popclip_id: string) => {
            try {
                const result = await fetch('/api/shopify/shop/featured-popclip', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ popclip_id }),
                });

                if (!result.ok) throw new Error('Failed to save feature popclip');
            } catch (error) {
                console.error(error);
                return null;
            }
        },
        isFeaturedPopclip: (popclip_id: string) => {
            return shopStore.featuredPopclipId == popclip_id;
        }
    });

export default videoLiveSellingStore;
