import { countAbbreviation } from '@/utils/helpers';
import { BlockStack, Box, Button, Icon, InlineStack, Spinner, Text } from '@shopify/polaris';
import { CheckIcon, HeartIcon, PlusIcon, ViewIcon } from '@shopify/polaris-icons';
import { MouseEvent, useState } from 'react';

type CarouselClipProps = {
    clipData: any;
    selectedClips?: any;
    handleRemoveClip?: (metaIdentifier: string) => void;
    handleSelectClip?: (clipData: any) => void;
    hideSelectBtn?: boolean;
    cursor?: string;
    setLoadedClipID:  () => void;
};

export default function CarouselClip({
    clipData,
    selectedClips,
    handleRemoveClip,
    handleSelectClip,
    hideSelectBtn,
    cursor = 'pointer',
    setLoadedClipID,
}: CarouselClipProps) {
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    
    const handleVideoPlayOnHover = (e: MouseEvent) => {
        const elm = e.target as HTMLVideoElement;
        elm.play();
    };

    const handleVideoResetOnOut = (e: MouseEvent) => {
        const elm = e.target as HTMLVideoElement;
        elm.pause();
        elm.currentTime = 0;
    };

    const handleLoadedData = () => {
        setIsVideoLoading(false)
        if(clipData?.metaIdentifier){
            setLoadedClipID()
        }
    }

    return (
        <Box
            background="bg-fill"
            borderRadius="200"
            overflowX="hidden"
            overflowY="hidden"
            borderColor="border"
            borderWidth="025"
            shadow="100"
        >
            {clipData?.status !== 'processed' ? (
                <VideoLoader text="Processing..." />
            ) : (
                <>
                    {isVideoLoading && <VideoLoader text="Loading..." />}
                    <div
                        style={{
                            position: 'relative',
                            display: isVideoLoading
                                ? 'none'
                                : 'block',
                        }}
                    >
                    <video
                        width="149"
                        height="242"
                        muted
                        style={{
                            objectFit: 'cover',
                            cursor: cursor,
                            width: !hideSelectBtn ? '149px' : '100%',
                            display: isVideoLoading ? 'none' : 'block',
                        }}
                        onMouseOver={handleVideoPlayOnHover}
                        onMouseOut={handleVideoResetOnOut}
                        onLoadedData={handleLoadedData}
                    >
                        <source
                            src={`${clipData.cdnUrl}${clipData.metaData.thumbnailURL}`}
                            type="video/mp4"
                        />
                        Your browser does not support the video
                    </video>
                    {(clipData?.showViews) && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                width: '100%',
                                color: 'white',
                            }}
                        >
                            <Box padding="200">
                                <InlineStack
                                    align="space-between"
                                    blockAlign="center"
                                >
                                    {clipData?.showViews && (
                                        <InlineStack
                                            gap="200"
                                            blockAlign="center"
                                        >
                                            <Icon source={ViewIcon} />{' '}
                                            <Text as="p" fontWeight="bold">
                                                {countAbbreviation(
                                                    clipData?.clipViews || 0,
                                                )}
                                            </Text>
                                        </InlineStack>
                                    )}
                                    {clipData?.showLikes && (
                                        <InlineStack
                                            gap="200"
                                            blockAlign="center"
                                        >
                                            <Icon source={HeartIcon} />{' '}
                                            <Text as="p" fontWeight="bold">
                                                {countAbbreviation(
                                                    clipData?.clipLikes,
                                                )}
                                            </Text>
                                        </InlineStack>
                                    )}
                                </InlineStack>
                            </Box>
                        </div>
                    )}
                    </div>
                </>
            )}
            <Box
                paddingInline="300"
                paddingBlockEnd={!hideSelectBtn ? '300' : '0'}
            >
                <BlockStack align="start" inlineAlign="start" gap="300">
                    <div style={{ width: '128px', userSelect: isVideoLoading ? 'none' : 'auto'}} >
                        <Text as="p" truncate>
                            {clipData?.metaData?.videoTitle || ''}
                        </Text>
                    </div>
                    {!hideSelectBtn && (
                        <>
                            {selectedClips.includes(clipData.metaIdentifier) ? (
                                <Button
                                    onClick={() =>
                                        handleRemoveClip &&
                                        handleRemoveClip(
                                            clipData?.metaIdentifier,
                                        )
                                    }
                                    variant="primary"
                                    tone="success"
                                    icon={CheckIcon}
                                >
                                    Selected
                                </Button>
                            ) : (
                                <Button
                                    onClick={() =>
                                        handleSelectClip && handleSelectClip(clipData)
                                    }
                                    icon={PlusIcon}
                                >
                                    Select
                                </Button>
                            )}
                        </>
                    )}
                </BlockStack>
            </Box>
        </Box>
    );
};

const VideoLoader = ({ text }: { text: string }) => (
    <Box minHeight="242px" minWidth="149px">
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '248px',
                gap: '4px',
                flexDirection: 'column',
                userSelect: 'none'
            }}
        >
            <Spinner size="small" />
            <Text as="p">{text}</Text>
        </div>
    </Box>
);