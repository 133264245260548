import {
    BlockStack,
    Box,
    Divider,
    Frame,
    Icon,
    InlineStack,
    Link,
    Loading,
    Page,
    Spinner,
} from '@shopify/polaris';
import {
    ArrowLeftIcon,
    CaretDownIcon,
    CaretUpIcon,
} from '@shopify/polaris-icons';
import '../../../assets/css/BaseLayout.scss';
import { useEffect, useState } from 'react';
import { SaveBar } from '@shopify/app-bridge-react';
import { shopStore } from '@/stores';
import { useSnapshot } from 'valtio';
import ProgressStatusPopup from '../../Components/ProgressStatusPopup';
import ZendeskSupportWidget from '@/components/common/ZendeskSupportWidget';

type BaseLayoutProps = {
    isLoading?: boolean;
    bodyIsLoading?: boolean;
    showProgressStatus?: boolean;
    children: React.ReactNode;
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    headerAction?: JSX.Element;
    backToLink?: string;
    saveBarPrimaryAction?: () => void;
    saveBarSecondaryAction?: () => void;
};

type LoadingComponentProps = {
    loading: boolean;
    children: React.ReactNode;
};

export function BaseLayout({
    isLoading,
    bodyIsLoading,
    title,
    subtitle,
    headerAction,
    backToLink,
    saveBarPrimaryAction,
    saveBarSecondaryAction,
    showProgressStatus,
    children,
}: BaseLayoutProps) {
    const [showProgressPopup, setShowProgressPopup] = useState(false);
    const { getStartedStatus } = useSnapshot(shopStore);

    useEffect(() => {
        // Restore scroll position on page load
        window.scrollTo(0, 0);
    }, []);
    
    const toggleProgressPopup = () => {
        setShowProgressPopup(!showProgressPopup);
    };

    return (
        <Page fullWidth>
            <SaveBar id="shopify-save-bar">
                <button variant="primary" onClick={saveBarPrimaryAction} />
                <button onClick={saveBarSecondaryAction} />
            </SaveBar>
            <RenderLoadedComponent loading={isLoading || false}>
                <Box background="bg-fill" borderRadius="150" width="min(85rem, 100%)">
                    <BlockStack gap="300">
                        <div style={{ position: 'sticky', top: '0', zIndex: '5' }}>
                            <Box background="bg-fill" paddingBlockStart="600">
                                <Box paddingInline="800" paddingBlockEnd="300">
                                    <InlineStack
                                        align="space-between"
                                        wrap={false}
                                    >
                                        <div
                                            style={{
                                                minHeight:
                                                    !subtitle &&
                                                    !showProgressStatus
                                                        ? '38px'
                                                        : '62px',
                                            }}
                                        >
                                            <InlineStack
                                                gap="400"
                                                align="start"
                                                blockAlign="center"
                                            >
                                                {backToLink && (
                                                    <Link
                                                        url={backToLink}
                                                        monochrome
                                                        removeUnderline
                                                    >
                                                        <Icon
                                                            source={
                                                                ArrowLeftIcon
                                                            }
                                                        />
                                                    </Link>
                                                )}
                                                <div>
                                                    <h1 className="page-heading">
                                                        {title}
                                                    </h1>
                                                    {subtitle && (
                                                        <Box paddingBlockStart="100">
                                                            {typeof subtitle ==
                                                            'string'
                                                                ? subtitle
                                                                : subtitle}
                                                        </Box>
                                                    )}
                                                </div>
                                            </InlineStack>
                                        </div>
                                        {headerAction
                                            ? headerAction
                                            : showProgressStatus && (
                                                  <div
                                                      style={{
                                                          position: 'relative',
                                                      }}
                                                  >
                                                      <div
                                                          className="get-started-progress"
                                                          onClick={
                                                              toggleProgressPopup
                                                          }
                                                      >
                                                          <div
                                                              className="circular-progress"
                                                              style={{
                                                                  background: `conic-gradient(#fff ${
                                                                      (getStartedStatus.completionPercentage /
                                                                          100) *
                                                                      360
                                                                  }deg, #898787 0deg)`,
                                                              }}
                                                          >
                                                              <div className="inner-circle"></div>
                                                          </div>
                                                          <div>
                                                              <p>Get Started</p>
                                                              <p>
                                                                  {
                                                                      getStartedStatus.completionPercentage
                                                                  }
                                                                  % Complete
                                                              </p>
                                                          </div>
                                                          <div
                                                              style={{
                                                                  cursor: 'pointer',
                                                                  padding:
                                                                      '2px 4px',
                                                              }}
                                                          >
                                                              <Icon
                                                                  source={
                                                                      showProgressPopup
                                                                          ? CaretUpIcon
                                                                          : CaretDownIcon
                                                                  }
                                                              />
                                                          </div>
                                                      </div>
                                                      {showProgressPopup && (
                                                          <ProgressStatusPopup
                                                              toggleProgressPopup={
                                                                  toggleProgressPopup
                                                              }
                                                              showProgressPopup={
                                                                  showProgressPopup
                                                              }
                                                          />
                                                      )}
                                                  </div>
                                              )}
                                    </InlineStack>
                                </Box>
                                <Divider />
                            </Box>
                        </div>
                        <div style={{ overflow: 'hidden' }}>
                            <Box paddingInline="800">
                                {bodyIsLoading ? (
                                    <div
                                        style={{
                                            height: '40rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Spinner size="large" />
                                    </div>
                                ) : (
                                    children
                                )}
                            </Box>
                            <p className="page-watermark">Videeo</p>
                        </div>
                    </BlockStack>
                </Box>
                <ZendeskSupportWidget />
            </RenderLoadedComponent>
        </Page>
    );
}

const RenderLoadedComponent = ({
    loading,
    children,
}: LoadingComponentProps) => (
    <>
        {loading && (
            <Frame>
                <Loading />
            </Frame>
        )}
        {!loading && (
            <div className="base-layout-page-container">{children}</div>
        )}
    </>
);
