import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { shopStore } from "@/stores";
import { vfsRoutes } from "@/constants/routes.constants";
import { isDateBeforeNewUI } from '@/utils/helpers';

type ComponentProps = { children: React.ReactNode };

const RequireGetStartedSteps = ({ children }: ComponentProps) => {
    const { getStartedStatus, onBoardedOn } = useSnapshot(shopStore);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const redirectTo = vfsRoutes.getStarted;

    useEffect(() => {
        if (!isDateBeforeNewUI(new Date(onBoardedOn || ''))) {
            if (
                (
                    !getStartedStatus.events.STEP_ENABLE_VIDEEO_SDK ||
                    !getStartedStatus.events.STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC ||
                    !getStartedStatus.events.STEP_ADD_YOUR_FIRST_POPCLIP
                ) && pathname !== redirectTo
            ) {
                navigate(redirectTo);
            }
        }
    }, [getStartedStatus]);

    return <>{children}</>;
};

export default RequireGetStartedSteps;
