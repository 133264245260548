const LAYOUT={
    xaxis: {
        tickangle: 0, // Rotates x-axis labels
        automargin: true, // Adjusts margins for long labels
    },
    yaxis: {
        visible: false, // Hides the y-axis
        showgrid: false, // Hides gridlines
    },
    showlegend: false,
    autosize: true,
    height: 120,
    margin: { t: 20, l: 30, r: 0, b: 40 },
    plot_bgcolor: 'rgba(0,0,0,0)', // Transparent background for gradient
    dragmode: "pan",
}

const CONFIG={
    responsive: true, // Makes the chart responsive
    "displaylogo": false,
}

const LINE_SMOOTHING_VALUE = 0.4

export {
    LAYOUT,
    CONFIG,
    LINE_SMOOTHING_VALUE,
}