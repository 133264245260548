import SvgImages from '../../../../assets/images/SvgImageJson.json';
import "./VfsImageComponent.scss"

interface VfsImageComponentProps {
    iconName: string;
    size?: string;
    color?: string;
}

const VfsImageComponent = ({
    iconName,
    size = '20px',
    color = '#fff',
}: VfsImageComponentProps) => {
    return iconName ? (
        <div
            className={'__vfs_image_component'}
            style={{
                fill: color, 
                width: size, 
                height: size
            }}
            dangerouslySetInnerHTML={{ __html: SvgImages[iconName] }}
        ></div>
    ) : null;
};

export default VfsImageComponent;
