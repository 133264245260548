import {
    ActionList,
    BlockStack,
    Box,
    Button,
    Divider,
    Grid,
    InlineStack,
    Popover,
    Spinner,
    Text,
} from '@shopify/polaris';
import CarouselClip from './CarouselClip';
import { useCallback, useState } from 'react';
import { createCarousel } from '../../../assets/images';
import { PlusIcon, SortIcon } from '@shopify/polaris-icons';
import { COLLECTION, CUSTOM } from '@/constants/popclipsFeature.constants';
import emptyCollectionState from '../../../assets/images/emptyCollectionState.png';
interface CarouselCLipsPreviewPropsTypes {
    selectedType: string;
    selectedClipData: [];
    isApiLoading: boolean;
    selectedCollection: string[];
    setClipsObjsArr: (clipsData: any) => void;
    toggleOpenModal: () => void;
}
export default function CarouselClipsPreview({
    selectedType,
    selectedClipData,
    isApiLoading,
    selectedCollection,
    setClipsObjsArr,
    toggleOpenModal,
}: CarouselCLipsPreviewPropsTypes) {
    const [popoverActive, setPopoverActive] = useState(false);
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragOver, setDragOver] = useState<number | null>(null);
    const [sortByText, setSortByText] = useState('');
    const [loadedPopclips, setLoadedPopclips] = useState<string[]>([]);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const sortClipsArrayInAscending = () => {
        const sortedClips = selectedClipData.sort((a: any, b: any) => {
            return a.clipViews - b.clipViews;
        });
        setClipsObjsArr(sortedClips);
        setPopoverActive(!popoverActive);
        setSortByText('by views: Low to High');
    };

    const sortClipsArrayInDescending = () => {
        const sortedClips = selectedClipData.sort((a: any, b: any) => {
            return b.clipViews - a.clipViews;
        });
        setClipsObjsArr(sortedClips);
        setPopoverActive(!popoverActive);
        setSortByText('by views: High to Low');
    };

    const handleDragStart = (index: number) => {
        setDraggedIndex(index);
    };

    const handleDragOver = (
        e: React.DragEvent<HTMLDivElement>,
        index: number,
    ) => {
        e.preventDefault();
        setDragOver(index);
    };

    const handleDrop = (index: number) => {
        if (draggedIndex === null) return;

        const reorderedVideos = [...selectedClipData];
        const [movedVideo] = reorderedVideos.splice(draggedIndex, 1);
        reorderedVideos.splice(index, 0, movedVideo);

        setClipsObjsArr(reorderedVideos);
        setDraggedIndex(null);
        setDragOver(null);
        setSortByText('');
    };

    const handleLoadedData = (metaIdentifier: string) => {
        setLoadedPopclips((prevLoadedPopclips) => [
            ...prevLoadedPopclips,
            metaIdentifier
        ])
    }

    return (
        <Box padding="200">
            <Box paddingBlockEnd="400">
                <InlineStack blockAlign="center" align="space-between">
                    <BlockStack inlineAlign="start">
                        <Text variant="headingMd" as="h6">
                            Preview Carousel and Reorder Clips
                        </Text>
                        <Text as="p" tone="subdued">
                            Rearrange your carousel by dragging and dropping clips of your choice
                        </Text>
                    </BlockStack>
                    {!isApiLoading && selectedClipData?.length > 0 && (
                        <BlockStack inlineAlign="end">
                            <Popover
                                active={popoverActive}
                                activator={
                                    <Button
                                        onClick={togglePopoverActive}
                                        icon={SortIcon}
                                        disclosure
                                    >
                                        Sort {sortByText}
                                    </Button>
                                }
                                autofocusTarget="first-node"
                                onClose={togglePopoverActive}
                            >
                                <ActionList
                                    actionRole="menuitem"
                                    items={[
                                        {
                                            content:
                                                'Sort by views: High to Low',
                                            onAction:
                                                sortClipsArrayInDescending,
                                        },
                                        {
                                            content:
                                                'Sort by views: Low to High',
                                            onAction: sortClipsArrayInAscending,
                                        },
                                    ]}
                                />
                            </Popover>
                        </BlockStack>
                    )}
                </InlineStack>
                <Box paddingBlock="200">
                    <Divider />
                </Box>
            </Box>

            {isApiLoading && (
                <InlineStack align="center">
                    <Spinner />
                </InlineStack>
            )}

            <Grid columns={{ xs: 3, sm: 4, md: 6, lg: 6, xl: 6 }}>
                {selectedType == CUSTOM && (
                    <Grid.Cell>
                        <Box
                            paddingInline="400"
                            paddingBlock="600"
                            minHeight="15rem"
                            borderStyle="dashed"
                            borderWidth="050"
                            borderColor="border-tertiary"
                            borderRadius="150"
                        >
                            <BlockStack
                                gap="300"
                                align="center"
                                inlineAlign="center"
                            >
                                <img
                                    src={createCarousel}
                                    width="125"
                                    alt="Create Carousel Placeholder"
                                />
                                <Text as="p" alignment="center">
                                    Add Popclips to your custom carousel
                                </Text>
                                <Button
                                    variant="primary"
                                    icon={PlusIcon}
                                    onClick={toggleOpenModal}
                                >
                                    Add Popclips
                                </Button>
                            </BlockStack>
                        </Box>
                    </Grid.Cell>
                )}
                {selectedClipData?.length > 0 &&
                    selectedClipData.map((clip: any, index: number) => (
                        <Grid.Cell key={clip?.metaIdentifier || index}>
                            <div>{loadedPopclips.includes(clip?.metaIdentifier)}</div>
                            <div
                                draggable={loadedPopclips.includes(clip?.metaIdentifier)}
                                key={index}
                                onDragStart={() => handleDragStart(index)}
                                onDragOver={(e) => handleDragOver(e, index)}
                                onDrop={() => handleDrop(index)}
                                style={
                                    index == dragOver
                                        ? { borderRight: '2px solid blue' }
                                        : {}
                                }
                            >
                                <CarouselClip
                                    clipData={clip}
                                    cursor={loadedPopclips.includes(clip?.metaIdentifier) ? "grab" : "pointer"}
                                    hideSelectBtn
                                    setLoadedClipID={() => handleLoadedData(clip?.metaIdentifier || '')}
                                />
                            </div>
                        </Grid.Cell>
                    ))}
            </Grid>

            {selectedType == COLLECTION && selectedCollection?.length < 1 && (
                <Box>
                    <BlockStack gap="200" align="center" inlineAlign="center">
                        <img src={emptyCollectionState} alt="Empty State" />
                        <Text variant="headingMd" as="h6" alignment="center">
                            Nothing here yet...
                        </Text>
                    </BlockStack>
                </Box>
            )}

            {!isApiLoading &&
                selectedType == COLLECTION &&
                (selectedClipData == null || selectedClipData?.length < 1) &&
                selectedCollection?.length > 0 && (
                    <Text variant="bodyLg" as="p" alignment="center">
                        No Popclip found. Please try again with a different
                        collection.
                    </Text>
                )}
        </Box>
    );
}
