import { useState, MouseEvent, useEffect, Suspense, lazy } from 'react';
import {
    InlineGrid,
    Button,
    InlineStack,
    BlockStack,
    Box,
    Text,
    Spinner,
    Popover,
    ActionList,
    Icon,
    Tooltip,
} from '@shopify/polaris';
import {
    SortIcon,
    EditIcon,
    DeleteIcon,
    CodeIcon,
    ViewIcon,
    HeartIcon,
    MenuHorizontalIcon,
} from '@shopify/polaris-icons';
import { AutocompleteInput } from '@/components/common';
import { AlertModal } from '@/components/common/AlertModal';
import { useMediaGalleryAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { popclipsRoutes } from '@/constants/routes.constants';
import { useNavigate } from 'react-router-dom';
import type { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';
import shopStore from '@/stores/shopStore';
import { useSnapshot } from 'valtio';
import { videoLiveSellingStore } from '@/stores';
import { countAbbreviation } from '@/utils/helpers';
import { popclipsLoading } from '../../../assets/images';

const LightboxComponent = lazy(
    () => import('@/components/common/LightboxComponent'),
);

type ClipListingCardProps = {
    clipDataArray: ListMediaByIdResponse[];
    refreshData: (goToPrevPage: boolean, clipMetaId: string) => void;
};

type lightboxSlide = import('yet-another-react-lightbox').Slide;

const ClipListingCard = ({
    clipDataArray,
    refreshData,
}: ClipListingCardProps) => {
    const navigate = useNavigate();
    const [popoverForId, setPopoverForId] = useState<string | number | null>();
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [lighboxSlideArray, setLighboxSlideArray] = useState<lightboxSlide[]>([]);
    const [showLightBox, setShowLightBox] = useState<string | null>(null);
    const { shopifyDomain } = useSnapshot(shopStore);

    useEffect(() => {
        let tempArray: lightboxSlide[] = [];
        let clipsUrlArray: lightboxSlide[] = [];
        let startPositionFound = false;

        clipDataArray.forEach((clipData) => {
            if (clipData.status !== 'processed') return;

            if (
                !startPositionFound &&
                clipData.metaIdentifier == showLightBox
            ) {
                startPositionFound = true;
            }

            if (startPositionFound) {
                clipsUrlArray.push({
                    type: 'video',
                    sources: [
                        {
                            src: `${clipData.cdnUrl}${clipData.metaData.thumbnailURL}`,
                            type: 'video/mp4',
                        },
                    ],
                });
            } else {
                tempArray.push({
                    type: 'video',
                    sources: [
                        {
                            src: `${clipData.cdnUrl}${clipData.metaData.thumbnailURL}`,
                            type: 'video/mp4',
                        },
                    ],
                });
            }
        });

        clipsUrlArray = [...clipsUrlArray, ...tempArray];
        setLighboxSlideArray(clipsUrlArray);
    }, [showLightBox, clipDataArray]);

    const fetch = useMediaGalleryAuthFetch();

    const onChangeAutocompleteValue = (value: string) => {
        console.log(value);
        return []; // don't want suggestions
    };

    const toggleActive = (id: number | string | null) => {
        setPopoverForId(id == popoverForId ? null : id);
    };

    const handleEmbedCodeClick = async () => {
        const clipFound = clipDataArray.find(({ metaIdentifier }) => metaIdentifier == popoverForId);
        if (!clipFound) return;

        const urlString = `https://${import.meta.env.MODE == 'qa' ? 'dev.' : ''}popshop.live/shopifyClips/${shopStore.popshopliveSellerId}/${clipFound.metaIdentifier}`;
        const dataLakeURL = `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/analytics`;
        const iframeStr = generateIframeString(
            urlString,
            dataLakeURL,
            clipFound.metaData.videoTitle,
            shopifyDomain,
            clipFound,
        );

        try {
            await copyToClipboard(iframeStr);
            shopify.toast.show('Embed Code copied to your clipboard', { isError: false, duration: 5000 });
        } catch (error) {
            console.error('Error while copying iframe: ', error);
        } finally {
            toggleActive(null);
        }
    };

    const handleEditClick = async () => {
        toggleActive(null);
        if (popoverForId) {
            videoLiveSellingStore.resetCreatePopClip();
            navigate(popclipsRoutes.edit.replace(':id', popoverForId.toString()));
        } 
    };

    const handleDeleteClip = async () => {
        setShowDeleteAlert(false);
        const clipFound = clipDataArray.find(({ metaIdentifier }) => metaIdentifier == popoverForId);
        if (!clipFound) return;

        try {
            await fetch('/csDeleteMedia', {
                method: 'POST',
                body: JSON.stringify({
                    id: clipFound.id,
                    metaIdentifier: clipFound.metaIdentifier,
                }),
            });

            refreshData(clipDataArray.length == 1, clipFound.metaIdentifier);

            shopify.toast.show('Clip successfully deleted.', {
                isError: false,
                duration: 5000,
            });
        } catch (error) {
            console.error(error);
            shopify.toast.show(
                'Something went wrong while trying to delete the clip.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
        } finally {
            toggleActive(null);
        }
    };

    return (
        <BlockStack gap="400">
            <div style={{ display: 'none' }}>
                <Box paddingInlineEnd="100">
                    <InlineGrid gap="300" columns={2}>
                        <AutocompleteInput
                            placeholderStr="Search by Clip Title"
                            onChangeValue={onChangeAutocompleteValue}
                            shouldDisabled={false}
                            debounceTimeout={500}
                            hideEmptyState={true}
                        />
                        <InlineStack align="end">
                            <Button icon={SortIcon} size="medium">
                                Sort
                            </Button>
                        </InlineStack>
                    </InlineGrid>
                </Box>
            </div>
            <InlineGrid columns={5} alignItems='start' gap="800">
                {clipDataArray.map((clip) => (
                    <PopclipTile
                        key={clip.metaIdentifier}
                        clip={clip}
                        openLightBoxClicked={(metaId: string) => setShowLightBox(metaId)}
                        popoverForId={popoverForId}
                        toggleActive={(metaId: string) => toggleActive(metaId)}
                        handleEmbedCodeClick={handleEmbedCodeClick}
                        handleEditClick={handleEditClick}
                        handleDeleteClick={() => setShowDeleteAlert(true)}
                    />
                ))}
            </InlineGrid>
            {showLightBox !== null && (
                <Suspense>
                    <LightboxComponent
                        slideArray={lighboxSlideArray}
                        handleCloseClick={() => setShowLightBox(null)}
                    />
                </Suspense>
            )}
            <AlertModal
                title="Delete Popclip"
                positiveBtnLabel="Delete"
                negativeBtnLabel="Cancel"
                positiveAction={handleDeleteClip}
                negativeAction={() => setShowDeleteAlert(!showDeleteAlert)}
                shouldShowModal={showDeleteAlert}
                closeModal={() => setShowDeleteAlert(!showDeleteAlert)}
                hasDeletebutton
                size="small"
            >
                <Text as="p">
                    Are you sure ? The clip will be permanently deleted.
                </Text>
            </AlertModal>
        </BlockStack>
    );
};

const PopclipTile = ({ clip, openLightBoxClicked, popoverForId, toggleActive, handleEmbedCodeClick, handleEditClick, handleDeleteClick }: any) => {
    const [isVideoLoading, setIsVideoLoading] = useState(true);

    const handleVideoPlayOnHover = (e: MouseEvent) => {
        const elm = e.target as HTMLVideoElement;
        elm.play();
    };

    const handleVideoResetOnOut = (e: MouseEvent) => {
        const elm = e.target as HTMLVideoElement;
        elm.pause();
        elm.currentTime = 0;
    };

    return (
        <Tooltip key={clip.metaIdentifier} content={clip.metaData.videoTitle} dismissOnMouseOut preferredPosition="below">
            <Box borderRadius="200" overflowX="hidden" overflowY="hidden">
                {clip.status !== 'processed' ? (
                    <VideoLoader text="Processing..." />
                ) : (
                    <>
                        {isVideoLoading && (
                            <VideoLoader text="Loading..." />
                        )}
                        <div style={{ position: 'relative', display: isVideoLoading ? 'none' : 'block' }}>
                            <video
                                muted
                                style={{
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    height: "400px",
                                    width: "auto",
                                    aspectRatio: "9 / 16",
                                    borderRadius: '.8rem',
                                }}
                                onMouseOver={handleVideoPlayOnHover}
                                onMouseOut={handleVideoResetOnOut}
                                onLoadedData={() => setIsVideoLoading(false)}
                                onClick={() => openLightBoxClicked(clip.metaIdentifier)}
                            >
                                <source
                                    src={`${clip.cdnUrl}${clip.metaData.processedURL}`}
                                    type="video/mp4"
                                />
                                Your browser does not support the video
                            </video>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    background: 'linear-gradient(rgb(41 41 41), rgba(0, 0, 0, 0))',
                                    width: '100%',
                                    height: '50px',
                                    color: 'white',
                                }}
                            >
                                <Box padding="200">
                                    <InlineStack align="space-between" blockAlign="center">
                                        {clip?.showViews && (
                                            <InlineStack gap="200" blockAlign="center">
                                                <Icon source={ViewIcon} />{' '}
                                                <Text as="p" fontWeight="bold">
                                                    {countAbbreviation(clip?.clipViews || 0)}
                                                </Text>
                                            </InlineStack>
                                        )}
                                        {videoLiveSellingStore.isFeaturedPopclip(clip.metaIdentifier) && (
                                            <div
                                                style={{
                                                    background: 'linear-gradient(93.56deg, #61C3FF 1.34%, #935AFC 98.41%)',
                                                    color: '#fff',
                                                    borderRadius: '5px',
                                                    padding: '3px 10px',
                                                }}
                                            >
                                                <Text as="p" fontWeight="bold">Featured</Text>
                                            </div>
                                        )}
                                        {clip?.showLikes && (
                                            <InlineStack gap="200" blockAlign="center">
                                                <Icon source={HeartIcon} />
                                                <Text as="p" fontWeight="bold">
                                                    {countAbbreviation(clip?.clipLikes)}
                                                </Text>
                                            </InlineStack>
                                        )}
                                    </InlineStack>
                                </Box>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '5px',
                                    left: 0,
                                    background: 'linear-gradient(#00000000, #000 80%)',
                                    width: '100%',
                                    color: 'white',
                                    fontSize: '24px',
                                    lineHeight: '30px',
                                    fontWeight: '600',
                                    padding: '10px',
                                    maxHeight: '193px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'end',
                                    borderRadius: '.5rem',
                                }}
                            >
                                <p style={{ width: 'calc(100% - 34px)', paddingRight: '10px', wordWrap: 'break-word' }}>{clip.metaData.videoTitle.length > 46 ? clip.metaData.videoTitle.substring(0, 46)+'...' : clip.metaData.videoTitle}</p>
                                <div className={`popclip_editbtbn_${clip.metaIdentifier}`} style={{
                                    paddingInline: '6px',
                                    background: 'linear-gradient(109deg, #0b0b0b, #494949)',
                                    borderRadius: '9px'
                                }}>
                                    <Popover
                                        active={clip.metaIdentifier == popoverForId}
                                        preferredAlignment="right"
                                        activator={
                                            <Button
                                                variant="monochromePlain"
                                                onClick={() => toggleActive(clip.metaIdentifier)}
                                                icon={MenuHorizontalIcon}
                                            />
                                        }
                                        onClose={() =>
                                            toggleActive(clip.metaIdentifier)
                                        }
                                    >
                                        <ActionList
                                            actionRole="menuitem"
                                            items={[
                                                {
                                                    content: 'Embed Code',
                                                    icon: CodeIcon,
                                                    onAction: handleEmbedCodeClick,
                                                },
                                                {
                                                    content: 'Edit',
                                                    icon: EditIcon,
                                                    onAction: handleEditClick,
                                                },
                                                {
                                                    content: 'Delete',
                                                    icon: DeleteIcon,
                                                    onAction: handleDeleteClick,
                                                },
                                            ]}
                                        />
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Box>
        </Tooltip>
    )
};

const VideoLoader = ({ text }: { text: string }) => (
    <Box borderWidth='025' borderColor='border'>
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '400px',
                gap: '4px',
                flexDirection: 'column',
                backgroundImage: `url(${popclipsLoading})`,
            }}
        >
            <Spinner size="large" />
            <Text as="p">{text}</Text>
        </div>
    </Box>
);

const generateIframeString = (
    url: string,
    dataLakeURL: string,
    title: string,
    shopifyDomain: string,
    clipMetadata: ListMediaByIdResponse,
) => {
    return `
        <iframe id="popshop-iframe-embed-${title}" allowFullScreen></iframe>
        <script type="text/javascript">
            (async () => {
                var iframe = document.getElementById('popshop-iframe-embed-${title}');
                iframe.style.width = '400px';
                iframe.style.height = '700px';
                iframe.style.border = '0';
                iframe.title = '${title}';
                iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
                iframe.src = '${url}';
                
                iframe.onload = async () => {
                    const body = {
                        element_action: "popclip_view_embedded",
                        element_category: "popclip",
                        event_timestamp:   Date.now(),
                        element_keyword: "${title}",
                        element_primary_id: "${clipMetadata.metaIdentifier}",
                        shop_id: "${shopifyDomain}",
                        entry_url: window.location.href,
                        event_context: {
                            gclid: null,
                            dclid: null,
                            gbraid: null,
                            wbraid: null,
                            fbclid: null,
                            twclid: null,
                            clid_src: null,
                            msclkid: null,
                            ttclid: null,
                            device_model:navigator.userAgentData?.brands[1]?.brand || "Unknown",
                            device_manufacturer: "Unknown",
                            os_version: navigator.platform.split(" ")?.[1] || "Unknown",
                            operating_system: navigator?.userAgentData?.platform || navigator?.platform || "Unknown",
                            notifications_enabled: false,
                            app_tracking_enabled: false,
                            language: navigator.language.split('-')[0],
                            locale: navigator.language,
                            location: document.location.href,
                            viewed_on: window.location.href,
                            duration: 0,
                        },
                        signed_in: false,
                        event_order_currency: null,
                        event_order_id: null,
                        event_order_total: 0,
                        event_platform: "vfs_web",
                        event_platform_version: "1.03",
                        platform_user_id: "guest",
                        utm_campaign: "${clipMetadata.id}_${clipMetadata.metaIdentifier}",
                        utm_content: "popclip_view_embedded",
                        utm_medium: "popclip",
                        utm_source: "shopify",
                        utm_term: "popclip_view_embedded",
                    };
                    fetch('${dataLakeURL}', {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(body),
                        redirect: "follow",
                    });
                };
            })();
        </script>
    `;
};

const copyToClipboard = (str: string) => {
    return navigator.clipboard.writeText(str);
};

export default ClipListingCard;
