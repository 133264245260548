import {
    BlockStack,
    Box,
    Button,
    Card,
    Icon,
    InlineGrid,
    InlineStack,
    Text,
} from '@shopify/polaris';
import { Dispatch, SetStateAction } from 'react';
import { LockFilledIcon } from '@shopify/polaris-icons';
import { popclipsCarouselBanner } from '../../assets/images';

type FeatureReleaseCalloutCardProps = {
    openEmbedWindow: () => void;
    setShowPlanUpgradeModal:Dispatch<SetStateAction<boolean>>;
};

const FeatureReleaseCalloutCard = ({
    openEmbedWindow,
    setShowPlanUpgradeModal,
}: FeatureReleaseCalloutCardProps) => {

    return (
        <Card background="bg-fill-info-secondary" padding="800">
            <InlineGrid columns={['twoThirds', 'oneThird']}>
                <BlockStack gap="400">
                    <Box
                        background="bg-fill-info"
                        borderRadius="full"
                        paddingInline="100"
                        width="9rem"
                    >
                        🎉 New Feature Alert
                    </Box>
                    <Text variant="headingMd" as="h6">
                        Presenting Popclips now on Website!!
                    </Text>
                    <Text as="p">
                        Join thousands of retailers who have garnered millions
                        of views with Popclips! Upload your Popclip today and
                        make your website a destination shoppers can't resist.
                    </Text>
                    <Box>
                        <InlineStack gap="600" blockAlign="center">
                            <Button variant="primary" onClick={openEmbedWindow}>
                                Create All-Popclips Carousel
                            </Button>
                            <Button
                                variant="primary"
                                icon={
                                    <Icon source={LockFilledIcon} tone="base" />
                                }
                                onClick={() => setShowPlanUpgradeModal(true)}
                            >
                                Create Custom Carousel
                            </Button>
                        </InlineStack>
                    </Box>
                </BlockStack>
                <BlockStack align="center" inlineAlign="center">
                    <img
                        style={{
                            height: '8rem',
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                        src={popclipsCarouselBanner}
                    />
                </BlockStack>
            </InlineGrid>
        </Card>
    );
};

export default FeatureReleaseCalloutCard;
