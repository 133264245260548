import { proxy } from 'valtio';
import { hexColorRegex } from '@/utils/validation';

const setLocalStorageItem = (key: string, value: string) => {
    if (typeof value === 'undefined') {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, value);
    }
};
const buildMobileAppStore = proxy({
    isBuildAppEnabled: false,
    isIOSChecklistCardOpen: true,
    isSupportInfoCardOpen: false,
    isAppNameCardOpen: false,
    isAppIconLaunchScreenCardOpen: false,
    isAppSubmissionCardOpen: false,
    isFeaturedCollectionCardOpen: false,
    appleDevTeamId: localStorage.getItem('buildApp-appleDevTeamId') || '',
    setAppleDevTeamId: (id: string) => {
        buildMobileAppStore.appleDevTeamId = id;
        setLocalStorageItem('buildApp-appleDevTeamId', id);
    },
    desiredAppName: localStorage.getItem('buildApp-desiredAppName') || '',
    setDesiredAppName: (name: string) => {
        buildMobileAppStore.desiredAppName = name;
        setLocalStorageItem('buildApp-desiredAppName', name);
    },
    desiredAppNameSecond: '',
    setDesiredAppNameSecond: (name: string) => {
        buildMobileAppStore.desiredAppNameSecond = name;
        setLocalStorageItem('buildApp-desiredAppNameSecond', name);
    },
    desiredAppNameThird: '',
    setDesiredAppNameThird: (name: string) => {
        buildMobileAppStore.desiredAppNameThird = name;
        setLocalStorageItem('buildApp-desiredAppNameThird', name);
    },
    companyName: localStorage.getItem('buildApp-companyName') || '',
    setCompanyName: (name: string) => {
        buildMobileAppStore.companyName = name;
        setLocalStorageItem('buildApp-companyName', name);
    },
    appLogoUrl: '',
    launchScreenImageUrl: '',
    appLogoBgColor: '#000000',
    launchScreenBgColor: '#000000',
    appleDevTeamIdErr: '',
    companyNameErr: '',
    desiredAppNameErr: '',
    desiredAppNameSecondErr: '',
    desiredAppNameThirdErr: '',
    appLogoUrlErr: '',
    launchScreenImageUrlErr: '',
    appLogoBgColorErr: '',
    launchScreenBgColorErr: '',
    openCard: (cardName: string, isOpen: boolean) => {
        buildMobileAppStore.isFeaturedCollectionCardOpen = false;
        buildMobileAppStore.isIOSChecklistCardOpen = false;
        buildMobileAppStore.isSupportInfoCardOpen = false;
        buildMobileAppStore.isAppNameCardOpen = false;
        buildMobileAppStore.isAppIconLaunchScreenCardOpen = false;
        buildMobileAppStore.isAppSubmissionCardOpen = false;
        buildMobileAppStore[cardName] = isOpen;
    },
    setIsFeaturedCollectionCardOpen: (isOpen: boolean) => {
        buildMobileAppStore.openCard('isFeaturedCollectionCardOpen', isOpen);
    },
    setIsIOSChecklistCardOpen: (isOpen: boolean) => {
        buildMobileAppStore.openCard('isIOSChecklistCardOpen', isOpen);
    },
    setIsSupportInfoCardOpen: (isOpen: boolean) => {
        buildMobileAppStore.openCard('isSupportInfoCardOpen', isOpen);
    },
    setIsAppNameCardOpen: (isOpen: boolean) => {
        buildMobileAppStore.openCard('isAppNameCardOpen', isOpen);
    },
    setIsAppIconLaunchScreenCardOpen: (isOpen: boolean) => {
        buildMobileAppStore.openCard('isAppIconLaunchScreenCardOpen', isOpen);
    },
    setIsAppSubmissionCardOpen: (isOpen: boolean) => {
        buildMobileAppStore.openCard('isAppSubmissionCardOpen', isOpen);
    },
    setAppLogoUrl: (url: string) => {
        buildMobileAppStore.appLogoUrl = url;
        setLocalStorageItem('buildApp-appLogoUrl', url);
    },
    setLaunchScreenImgUrl: (url: string) => {
        buildMobileAppStore.launchScreenImageUrl = url;
        setLocalStorageItem('buildApp-launchScreenImageUrl', url);
    },
    removeAppLogo: () => {
        buildMobileAppStore.appLogoUrl = '';
    },
    removeLaunchScreenImg: () => {
        buildMobileAppStore.launchScreenImageUrl = '';
    },
    hasAppleDevTeamIdErr: () => {
        buildMobileAppStore.appleDevTeamIdErr =
            buildMobileAppStore.appleDevTeamId.trim() === ''
                ? 'This is a required field'
                : '';
    },
    hasCompanyNameErr: () => {
        buildMobileAppStore.companyNameErr =
            buildMobileAppStore.companyName.trim() === ''
                ? 'This is a required field'
                : '';
    },
    hasDesiredAppNameErr: () => {
        buildMobileAppStore.desiredAppNameErr =
            buildMobileAppStore.desiredAppName.trim() === ''
                ? 'This is a required field'
                : '';
    },
    hasDesiredAppNameSecondErr: () => {
        buildMobileAppStore.desiredAppNameSecondErr =
            buildMobileAppStore.desiredAppNameSecond.trim() === ''
                ? 'This is a required field'
                : '';
    },
    hasDesiredAppNameThirdErr: () => {
        buildMobileAppStore.desiredAppNameThirdErr =
            buildMobileAppStore.desiredAppNameThird.trim() === ''
                ? 'This is a required field'
                : '';
    },
    hasDunsCompletedAtErr: () => {
        buildMobileAppStore.dunsCompletedAtErr =
            !buildMobileAppStore.dunsCompletedAt
                ? 'This is a required field'
                : '';
    },
    hasIosDevCompletedAtErr: () => {
        buildMobileAppStore.iosDevCompletedAtErr =
            !buildMobileAppStore.iosDevCompletedAt
                ? 'This is a required field'
                : '';
    },
    hasAddDevEmailCompletedAtErr: () => {
        buildMobileAppStore.addDevEmailCompletedAtErr =
            !buildMobileAppStore.addDevEmailCompletedAt
                ? 'This is a required field'
                : '';
    },
    supportURL: '',
    supportURLErr: '',
    setSupportURL: (url: string) => {
        buildMobileAppStore.supportURL = url;
        setLocalStorageItem('buildApp-supportURL', url);
    },
    hasSupportURLErr: () => {
        if (!buildMobileAppStore.isSupportURLValid) {
            buildMobileAppStore.supportURLErr = 'Please enter a valid URL';
        } else if (buildMobileAppStore.supportURL.length === 0) {
            buildMobileAppStore.supportURLErr = 'This is a required field';
        }
    },
    get isSupportURLValid() {
        let urlIsValid = true;
        try {
            new URL(this.supportURL);
        } catch (e) {
            urlIsValid = false;
        }
        return this.supportURL.length > 0 && urlIsValid;
    },
    hasAppLogoUrlErr: () => {
        buildMobileAppStore.appLogoUrlErr =
            buildMobileAppStore.appLogoUrl.trim() === ''
                ? 'This is a required field'
                : '';
    },
    hasLaunchScreenImageUrlErr: () => {
        buildMobileAppStore.launchScreenImageUrlErr =
            buildMobileAppStore.launchScreenImageUrl.trim() === ''
                ? 'This is a required field'
                : '';
    },
    hasAppLogoBgColorErr: () => {
        const isValid =
            buildMobileAppStore.appLogoBgColor.length > 0 &&
            hexColorRegex.test(buildMobileAppStore.appLogoBgColor);

        buildMobileAppStore.appLogoBgColorErr = isValid
            ? ''
            : 'Invalid hex code, ex. #f6f6f6';
    },
    setAppLogoBgColor: (color: string) => {
        buildMobileAppStore.appLogoBgColor = color;
        setLocalStorageItem('buildApp-appLogoBgColor', color);
    },
    setLaunchScreenBgColor: (color: string) => {
        buildMobileAppStore.launchScreenBgColor = color;
        setLocalStorageItem('buildApp-launchScreenBgColor', color);
    },
    hasLaunchScreenBgColorErr: () => {
        const isValid =
            buildMobileAppStore.launchScreenBgColor.length > 0 &&
            hexColorRegex.test(buildMobileAppStore.launchScreenBgColor);

        buildMobileAppStore.launchScreenBgColorErr = isValid
            ? ''
            : 'Invalid hex code, ex. #f6f6f6';
    },
    showFormErrorLabel: false,
    dunsCompletedAt: 0,
    dunsCompletedAtErr: '',
    iosDevCompletedAt: 0,
    iosDevCompletedAtErr: '',
    addDevEmailCompletedAt: 0,
    addDevEmailCompletedAtErr: '',
    primaryColor: '',
    bannerColor: '',
    featuredShopifyCollectionGid: '',
    secondaryColor: '',
    rotatingAppleEmail: '',
    isAppSetupLoading: true,
    isAppConfigLoading: true,
    isAppRequestLoading: true,
    isAppleEmailLoading: true,
    isAppRequestSubmitted: false,
    androidAppStatus: '',
    iosAppStatus : '',
    iosLink: '',
    androidLink: '',
    androidBannerTone: 'info',
    androidStatus: '',
    androidDescription: '',
    iosBannerTone: 'info',
    iosStatus: '',
    iosDescription: '',
    customerIssueBannerTone: 'info',
    customerIssueStatus: '',
    customerIssueDescription: '',
    blockedIssueBannerTone: 'critical',
    blockedIssueStatus: '',
    blockedIssueDescription: '',
});

export default buildMobileAppStore;
