import { proxy } from 'valtio';
import shopStore from './shopStore';
import { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';

export type PopclipsCarouselItem = {
    updated_at: number;
    carousel_id: string;
    created_at: number;
    timestamp: number;
    customer_id: string;
    carousel_contents: string[];
    type_with_prefix: string;
    carousel_title: string;
    carousel_description: string;
    type: string;
    is_published: boolean;
    carousel_type: string;
    carousel_type_ids: string[];
};

interface PopclipsStoreState {
    popclipsCarousels: PopclipsCarouselItem[];
    carouselToEdit: PopclipsCarouselItem | null;
    carouselCurrentPage: number;
    carouselLastEvalKey: {
        [key: string]:
            | {
                  id: string;
                  fileTypeAndIdKey: string;
                  metaIdentifier: string;
              }
            | undefined;
    };
    clipsForEditCarousel: ListMediaByIdResponse[];
    getPopclipsCarousels: () => Promise<void>;
    saveCustomCarousel: (carouselData: any, isEdit: boolean) => Promise<{carousel_id: string}>;
    handleEditCarousel: (
        carousel: PopclipsCarouselItem,
        MGAuthFetch: any,
    ) => Promise<void>;
    getNewCarouselID: (customerId: string) => Promise<{carousel_id: string}>;
    getSearchedPopclips: (searchTerm: string, pageNo?: number) => Promise<any>;
}

const popclipsStore: PopclipsStoreState = proxy<PopclipsStoreState>({
    carouselCurrentPage: 1,
    carouselLastEvalKey: {},
    popclipsCarousels: [],
    clipsForEditCarousel: [],
    carouselToEdit: undefined as unknown as PopclipsCarouselItem,
    getPopclipsCarousels: async () => {
        if (!shopStore.popshopliveAccountCreated) return;

        try {
            const response = await fetch(
                `/api/popclips/carousels?customerId=${shopStore.shopifyDomain}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                },
            );

            if (!response.ok)
                throw new Error('Error fetching popclips carousels');

            const result = await response.json();
            popclipsStore.popclipsCarousels = result.data;
        } catch (error) {
            console.error('Error fetching popclips carousels', error);
        }
    },
    handleEditCarousel: async (
        carousel: PopclipsCarouselItem,
        MGAuthFetch: any,
    ) => {
        try {
            const { data } = await MGAuthFetch('/listMediabyTypeAndMetaId', {
                method: 'POST',
                body: JSON.stringify({
                    metaIds: carousel.carousel_type_ids,
                    sellerId: shopStore.popshopliveSellerId,
                }),
            });

            popclipsStore.carouselToEdit = carousel;
            const clipsArrayItems = data || [];
            await fetchLikesAndViewsPerClip(clipsArrayItems);
            const reorderedResponseClipsArrayItems = carousel.carousel_type_ids.map((id) =>
                data.find((item) => item.metaIdentifier === id)
              );
            popclipsStore.clipsForEditCarousel = reorderedResponseClipsArrayItems;            
        } catch (error) {
            console.error('Error fetching carousel clips:', error);
        }
    },
    saveCustomCarousel: async (carouselData: any, isEdit = false) => {
        try {
            const response = await fetch('/api/popclips/carousels', {
                method: isEdit ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(carouselData),
            });

            const result = await response.json();
            if (!response.ok) throw new Error(result.message);

            return result.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getNewCarouselID: async (customerId) => {
        try {
            const response = await fetch(`/api/popclips/carousels?customerId=${customerId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            const result = await response.json();
            if (!response.ok) throw new Error(result.message);

            return result.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getSearchedPopclips: async (searchTerm: string, pageNo = 1) => {
        try {
            if (!shopStore.shopifyDomain) return;

            const searchUrl = `${shopStore.popclipsServiceApi}/search?shopifyDomain=${shopStore.shopifyDomain}&searchValue=${searchTerm}&page=${pageNo}&size=14`;
            const response = await fetch(searchUrl, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) throw new Error('Error fetching popclips');

            const result = await response.json();
            return result.data;
        } catch (error) {
            console.error('Error fetching popclips', error);
            return;
        }
    },
});

export const fetchLikesAndViewsPerClip = async (filterClipArray: any) => {
    const clipIDs = filterClipArray.map(
        ({ id, metaIdentifier }) => id + '_' + metaIdentifier,
    );

    if (clipIDs?.length < 1) {
        return;
    }

    const [views_response, likes_response] = await Promise.all([
        fetch('/api/popclips/views/fetch', {
            method: 'POST',
            body: JSON.stringify({
                customer_id: shopStore.shopifyDomain,
                clip_ids: clipIDs,
            }),
        }),
        fetch('/api/popclips/likes/fetch', {
            method: 'POST',
            body: JSON.stringify({
                customer_id: shopStore.shopifyDomain,
                clip_ids: clipIDs,
                user_id: shopStore.shopifyDomain,
            }),
        }),
    ]);

    if (views_response.status === 200) {
        const { data } = await views_response.json();
        updateFilterClipArrayWithViews(filterClipArray, data);
    }

    if (likes_response.status === 200) {
        const { data } = await likes_response.json();
        updateFilterClipArrayWithLikes(filterClipArray, data);
    }
};

const updateFilterClipArrayWithLikes = (filterClipArray: any, data: any) => {
    if (data?.length > 0) {
        data.forEach((item) => {
            const clipsFound = filterClipArray.find(
                ({ metaIdentifier, id }) =>
                    `${id}_${metaIdentifier}` === item.clip_id,
            );

            if (clipsFound) {
                clipsFound.showLikes = item?.likes || item?.likes === 0;
                clipsFound.clipLikes = item?.likes;
            }
        });
    }
};

const updateFilterClipArrayWithViews = (filterClipArray: any, data: any) => {
    if (data?.length > 0) {
        data.forEach((item) => {
            const clipsFound = filterClipArray.find(
                ({ metaIdentifier, id }) =>
                    `${id}_${metaIdentifier}` === item.clip_id,
            );
            if (clipsFound) {
                clipsFound.showViews =
                    item?.clip_total_views || item?.clip_total_views === 0;
                clipsFound.clipViews = item?.clip_total_views;
            }
        });
    }
};

export default popclipsStore;
