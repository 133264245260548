import { POPCLIPS_ID_STR } from '@/constants/planFeatureCard.constants';
import { BYPASS_GATING_FOR_DOMAINS } from '@/constants/shopify.constants';
import { billingStore, shopStore } from '@/stores';

export const gatingDataForQA = async () => {
    try {
        const rows = await getDataFromDatalake(
            shopStore.shopifyDomain,
            'https://data-lake-foundation.cs-api.com/query/prepared?outputFormat=json',
            'qa_vfs_inproduct_reporting_popclips_gating_billingperiod_summary',
        );

        if (rows.length < 2) {
            return undefined;
        }
        const processedData = getProcessedData(rows);
        const subData = shopStore.subscription;
        const removeIncludedAddonWhenHasExtra = (
            includedAddons,
            extraAddons,
        ) => {
            return includedAddons.filter((includedAddon) => {
                return !extraAddons.some(
                    (extraAddon) => extraAddon?.type === includedAddon?.type,
                );
            });
        };
        const plan = subData?.lineItems?.filter(
            ({ type }) => type === 'plan',
        )[0]?.item;
        const includedAddons =
            plan?.addons && plan?.addons?.length > 0
                ? billingStore.getFormattedAddonsForCart(plan.addons)
                : [];
        const extraAddons = subData?.lineItems
            .filter((item) => item?.type === 'addon')
            .map((row) => row?.item);
        const formattedExtraAddons =
            extraAddons?.length > 0
                ? billingStore.getFormattedAddonsForCart(extraAddons)
                : [];
        const formattedIncludedAddons =
            formattedExtraAddons.length > 0
                ? removeIncludedAddonWhenHasExtra(
                      includedAddons,
                      formattedExtraAddons,
                  )
                : includedAddons;
        const featureAddonData = [
            ...formattedIncludedAddons,
            ...formattedExtraAddons,
        ].filter((addon) => addon?.type == POPCLIPS_ID_STR);
        return {
            maxViews: featureAddonData[0]?.value,
            totalViews: processedData[0].total_views
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            limitAccess: BYPASS_GATING_FOR_DOMAINS.includes(
                shopStore.shopifyDomain,
            )
                ? false
                : Number(processedData[0].total_views) >=
                  Number(featureAddonData[0]?.value.replace(/,/g, '')),
            startDateOfViewsCount: processedData[0]?.start_date_of_views_count,
            typeOfDate: processedData[0]?.type_of_date,
        };
    } catch (error) {
        console.log(error);
    }
};
async function getDataFromDatalake(
    shopId: string,
    datalakeApiBaseUrl: string,
    statementName: string,
) {
    const response = await fetch(`${datalakeApiBaseUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            statementName: statementName,
            paramKeys: 'shopify_domain',
            shopify_domain: shopId,
        }),
        redirect: 'follow',
    });
    const jsonResult = await response.json();
    const rows = jsonResult?.ResultSet?.Rows || [];
    return rows;
}
function getProcessedData(rows: any) {
    try {
        const columns =
            rows[0]?.Data?.length &&
            rows[0]?.Data?.map((x: any) => x.VarCharValue);

        const processedData = [];

        for (let i = 1; i < rows.length; i++) {
            const eachRow = rows[i].Data;
            const popclipsMap: any = {};
            for (let j = 0; j < eachRow.length; j++) {
                popclipsMap[columns[j]] = eachRow[j]?.VarCharValue || null;
            }
            processedData.push(popclipsMap);
        }

        return processedData;
    } catch (error) {
        console.log(error);
        return [];
    }
}
