import PopClipsCard from '@/components/common/PopClipsCard';
import { popclipsRoutes } from '@/constants/routes.constants';
import { useMediaGalleryAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { popclipsStore, shopStore } from '@/stores';
import { PopclipsCarouselItem } from '@/stores/popclipsStore';
import { formatTimeToDateInLocalTime } from '@/utils/formatDate';
import { openEmbedWindow } from '@/utils/helpers';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import {
    BlockStack,
    Box,
    Button,
    Divider,
    EmptySearchResult,
    IndexTable,
    InlineStack,
    Spinner,
    Text,
} from '@shopify/polaris';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import CarouselPublishModal from '../../../pages/popclips/carouselComponents/CarouselPublishModal';
import { VfsButton, VfsImageComponent } from '@/components/common/v1';

const typeName = {
    'custom' : 'Selected by Popclip',
    'collection' : 'Selected by Collection'
}

export default function ManageCustomCarousels() {
    const navigate = useNavigate();
    const MGAuthFetch = useMediaGalleryAuthFetch();

    const themeWindowRef = useRef<Window | null>();
    const { popclipsCarousels } = useSnapshot(popclipsStore);
    const { getStartedStatus, popclipCarouselEmbedEnableUrl } = useSnapshot(shopStore);

    const [carouselToDelete, setCarouselToDelete] = useState<PopclipsCarouselItem>();
    const [openModal, setOpenModal] = useState(false);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [openPublishModal, setOpenPublishModal] = useState(false);

    useEffect(() => {
        (async () => {
            setIsApiLoading(true);
            await popclipsStore.getPopclipsCarousels();
            setIsApiLoading(false);
        })();
        return () => {
            popclipsStore.popclipsCarousels = [];
        }
    }, []);

    const handleEditCarousel = async (carousel: any) => {
        setIsApiLoading(true);
        if(carousel?.carousel_type == 'custom'){
            await popclipsStore.handleEditCarousel(carousel, MGAuthFetch);
        } else {
            popclipsStore.carouselToEdit = carousel;
        }
        navigate(popclipsRoutes.editCarousel);
    };

    const handleOnDeleteClick = (carousel: any) => {
        setCarouselToDelete(carousel);
        setOpenModal(true);
    };

    const handleDeleteCarousel = async () => {
        if (!carouselToDelete) return;

        setOpenModal(false);
        const { customer_id, carousel_id } = carouselToDelete;

        try {
            setIsApiLoading(true);
            const response = await fetch(
                `/api/popclips/carousels/${carousel_id}?customerId=${customer_id}`,
                { method: 'DELETE' },
            );

            if (!response.ok) throw new Error('Error deleting carousel');

            shopify.toast.show('Carousel deleted successfully', {
                duration: 2000,
                isError: false,
            });

            await popclipsStore.getPopclipsCarousels();
        } catch (error) {
            console.error('Error deleting carousel:', error);
            shopify.toast.show('Error deleting carousel', {
                duration: 2000,
                isError: true,
            });
        } finally {
            setIsApiLoading(false);
        }
    };

    const handleOpenEmbedWindow = async () => {
        const shopifyThemeEditorUrl = popclipCarouselEmbedEnableUrl.replace(
            'popclips-carousel',
            'custom-popclips-carousel',
        );
        openEmbedWindow(themeWindowRef, shopifyThemeEditorUrl);
        if (!getStartedStatus.events.STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL) {
            await shopStore.updateGetStartedStatus(
                'STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL',
            );
        }
    };

    return (
            <PopClipsCard>
                <BlockStack>
                    <Box paddingBlock="400">
                    <InlineStack align='space-between' blockAlign='center'>
                        <h1 className="page-heading">
                            Manage Carousels
                        </h1>
                    <InlineStack gap="200" align="end" blockAlign='stretch'>
                        {isApiLoading && <Spinner size="small" />}
                        <VfsButton
                            variant='secondary'
                            padding='6px'
                            onClick={() => setOpenPublishModal(true)}
                            disabled={isApiLoading}
                        >
                            Create All-Popclips Carousel
                        </VfsButton>
                        <VfsButton
                            padding='6px 8px'
                            onClick={handleOpenEmbedWindow}
                            disabled={isApiLoading}
                        >
                            Go To Theme Editor
                        </VfsButton>
                        <VfsButton
                            padding='6px 8px'
                            onClick={() =>
                                navigate(popclipsRoutes.createCarousel)
                            }
                            disabled={isApiLoading}
                        >
                            <div className='__flex-center-align' style={{ gap: '4px'}}>
                                <VfsImageComponent iconName="PlusIcon" />
                                <div>Create Custom Carousel</div>
                            </div>
                        </VfsButton>
                    </InlineStack>
                    </InlineStack>
                    </Box>
                    <Box paddingBlock="100">
                        <Divider />
                    </Box>
                    <IndexTable
                        loading={isApiLoading}
                        selectable={false}
                        hasZebraStriping
                        condensed={false}
                        resourceName={{
                            singular: 'Custom Carousel',
                            plural: 'Custom Carousels',
                        }}
                        itemCount={popclipsCarousels.length}
                        emptyState={
                            <EmptySearchResult
                                title="No Custom Carousels found"
                                description="Create a custom carousel to display on your store."
                                withIllustration
                            />
                        }
                        headings={[
                            { title: 'Carousel ID' },
                            { title: 'Title' },
                            { title: 'Type' },
                            { title: 'Created On' },
                            { title: 'Actions', alignment: 'center' },
                        ]}
                    >
                        {!isApiLoading &&
                            popclipsCarousels.map((carousel, index) => (
                                <IndexTable.Row
                                    position={index}
                                    id={carousel.carousel_id}
                                    key={carousel.carousel_id}
                                >
                                    <IndexTable.Cell>
                                        {carousel.carousel_id}
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                        <Box width="200px">
                                            <Text as="p" truncate>
                                                {carousel.carousel_title}
                                            </Text>
                                        </Box>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                        <Box width="200px">
                                            <Text as="p" truncate>
                                                {typeName?.[
                                                    carousel.carousel_type
                                                ]
                                                    ? typeName[
                                                          carousel.carousel_type
                                                      ]
                                                    : carousel.carousel_type}
                                            </Text>
                                        </Box>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                        {formatTimeToDateInLocalTime(
                                            carousel.created_at,
                                        )}
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                        <InlineStack gap="300" align="end">
                                            <Button
                                                disabled={isApiLoading}
                                                onClick={() =>
                                                    handleEditCarousel(carousel)
                                                }
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                tone="critical"
                                                variant="primary"
                                                onClick={() =>
                                                    handleOnDeleteClick(
                                                        carousel,
                                                    )
                                                }
                                                disabled={isApiLoading}
                                            >
                                                Delete
                                            </Button>
                                        </InlineStack>
                                    </IndexTable.Cell>
                                </IndexTable.Row>
                            ))}
                    </IndexTable>
                </BlockStack>
                <Modal open={openModal} onHide={() => setOpenModal(false)}>
                    <TitleBar title="Delete Custom Carousel!">
                        <button
                            variant="primary"
                            tone="critical"
                            onClick={handleDeleteCarousel}
                        >
                            Delete
                        </button>
                        <button onClick={() => setOpenModal(false)}>
                            Cancel
                        </button>
                    </TitleBar>
                    <Box padding="400">
                        <Text as="p">
                            Are you sure you want to delete the custom carousel?
                            This will remove the carousel from the store and
                            cannot be undone.
                        </Text>
                    </Box>
                </Modal>
                <CarouselPublishModal
                    title='Publish Carousel'
                    subHeader="Follow the steps below to publish all your clips in a carousel on your store."
                    urlLink="https://youtu.be/VlMVTEtkkgU?si=JxCFhq8mMU2CzGn5"
                    openPublishModal={openPublishModal}
                    hidePublishModal={() => setOpenPublishModal(false)}
                    carouselId=''
                    step1Enable={false}
                    isAllPopclipsCarousel={true}
                />
            </PopClipsCard>
    );
}
