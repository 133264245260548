import { shopStore, videoLiveSellingStore } from '@/stores';
import { BlockStack, Box, Button, ButtonGroup, Checkbox, Icon, InlineStack, Link, Spinner, Text, TextField } from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import AppVideoPicker from '@/components/common/AppVideoPicker';
import PopClipsCard from '@/components/common/PopClipsCard';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { popclipsRoutes } from '@/constants/routes.constants';
import { useNavigate } from 'react-router-dom';
import { LockFilledIcon } from '@shopify/polaris-icons';
import { generateFileFromVideoUrl } from '@/utils/helpers';
import { INSTAGRAM, TIKTOK } from '@/constants/planFeatureCard.constants';
import { BYPASS_GATING_FOR_DOMAINS } from '@/constants/shopify.constants';

type ClipsFormCardProps = {
    setShowSocialPlanUpdate: Dispatch<SetStateAction<boolean>>;
    setShowFeaturePopclipIntro: Dispatch<SetStateAction<boolean>>;
    setSocialButtonType: Dispatch<SetStateAction<string>>;
    isEditMode?: boolean;
};
const ClipsFormCard = ({
    isEditMode,
    setShowSocialPlanUpdate,
    setSocialButtonType,
    setShowFeaturePopclipIntro,
}: ClipsFormCardProps) => {
    const [isImportingReel, setIsImportingReel] = useState(false);
    const [shouldShowSocialImport, setShouldShowSocialImport] = useState(false);
    const { subscription, shopifyDomain } = useSnapshot(shopStore);

    const navigate = useNavigate();
    const {
        clipSourceType,
        clipSourceUrl,
        clipSourceUrlErr,
        clipTitle,
        clipTitleErr,
        savingPopclip,
        videoUrl,
        editClipData,
        isFeatured,
    } = useSnapshot(videoLiveSellingStore, {
        sync: true,
    });

    useEffect(() => {
        if (!isEditMode) {
            const gatingData = shopStore.featureGatingData?.popclips;

            if (
                gatingData &&
                gatingData?.maxViews != '-1' &&
                gatingData?.limitAccess
            ) {
                navigate(popclipsRoutes.list);
            }
        }
    }, []);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            'popclips',
            true,
        );
        const maxPrice = Math.max(...addonPrices);

        if (BYPASS_GATING_FOR_DOMAINS.includes(shopifyDomain)) {
            setShouldShowSocialImport(true);
        } else {
            setShouldShowSocialImport(maxPrice >= 29.99);
        }
    }, [subscription]);

    const getClipFromExternalSource = async () => {
        setIsImportingReel(true);
        let videoFile;
        let videoRes;

        if (clipSourceType === 'instagram') {
            videoRes = await videoLiveSellingStore.importInstaVideo();
        } else if (clipSourceType === 'tiktok') {
            videoRes = await videoLiveSellingStore.importTiktokVideo();
        }

        if (videoRes && videoRes.videoUrl) {
            videoFile = await generateFileFromVideoUrl(videoRes.videoUrl);
        } else {
            shopify.toast.show(
                'Something went wrong. Please try again in some time.',
                { isError: true, duration: 5000 },
            );
        }

        if (videoFile) {
            const formData = new FormData();
            const fileName = videoFile.name;
            const sanitizedName = `${fileName
                .slice(0, -3)
                .replace(/[^a-zA-Z0-9]/g, '')}.${fileName.slice(-3)}`;
            formData.append('file', videoFile, sanitizedName);

            videoLiveSellingStore.videoUrl = videoRes.videoUrl;
            videoLiveSellingStore.dropzoneFile = formData;
        } else {
            shopify.toast.show(
                'An error occurred in the upload. Please try again with the correct URL.',
                { isError: true, duration: 5000 },
            );
        }

        setIsImportingReel(false);
    };

    const onRemoveInstaVideo = () => {
        videoLiveSellingStore.dropzoneFile = null;
        videoLiveSellingStore.videoUrl = '';
        videoLiveSellingStore.clipSourceUrl = '';
    };

    return (
        <PopClipsCard>
            <BlockStack gap="300">
                <TextField
                    label="Clip Title:"
                    placeholder="Enter Clip Title"
                    autoComplete="off"
                    value={clipTitle}
                    onChange={(value) => {
                        videoLiveSellingStore.setClipTitle(value);
                        videoLiveSellingStore.hasClipTitleErr();
                    }}
                    onFocus={() => {
                        videoLiveSellingStore.clipTitleErr = '';
                    }}
                    error={clipTitleErr}
                    disabled={savingPopclip}
                />
                <Box padding="200" paddingBlockEnd="0">
                    <InlineStack align="center">
                        <ButtonGroup variant="segmented">
                            <Button
                                pressed={clipSourceType == 'instagram'}
                                icon={
                                    !shouldShowSocialImport ? (
                                        <Icon
                                            source={LockFilledIcon}
                                            tone="base"
                                        />
                                    ) : undefined
                                }
                                onClick={() => {
                                    setSocialButtonType(INSTAGRAM);
                                    shouldShowSocialImport
                                        ? (videoLiveSellingStore.clipSourceType =
                                              'instagram')
                                        : setShowSocialPlanUpdate(true);
                                }}
                                disabled={isEditMode}
                            >
                                Import from Instagram
                            </Button>
                            <Button
                                pressed={clipSourceType == 'tiktok'}
                                icon={
                                    !shouldShowSocialImport ? (
                                        <Icon
                                            source={LockFilledIcon}
                                            tone="base"
                                        />
                                    ) : undefined
                                }
                                onClick={() => {
                                    setSocialButtonType(TIKTOK);
                                    shouldShowSocialImport
                                        ? (videoLiveSellingStore.clipSourceType =
                                              'tiktok')
                                        : setShowSocialPlanUpdate(true);
                                }}
                                disabled={isEditMode}
                            >
                                Import from Tiktok
                            </Button>
                            <Button
                                pressed={clipSourceType == 'device'}
                                onClick={() =>
                                    (videoLiveSellingStore.clipSourceType =
                                        'device')
                                }
                                disabled={isEditMode}
                            >
                                Upload from Device
                            </Button>
                        </ButtonGroup>
                    </InlineStack>
                </Box>
                {['instagram', 'tiktok'].includes(clipSourceType) ? (
                    <BlockStack gap="200" inlineAlign="center">
                        <Box width="32rem" paddingBlockStart="200">
                            <Text as="p" tone="subdued" alignment="center">
                                By clicking on import, you certify that you have
                                obtained all necessary permissions to share this
                                content and confirm your agreement with our{' '}
                                <Link
                                    url="https://commentsold.com/legal/main-terms"
                                    target="_blank"
                                    removeUnderline
                                >
                                    <Text
                                        as="span"
                                        tone="magic"
                                        fontWeight="semibold"
                                    >
                                        Main Services Agreement
                                    </Text>
                                </Link>
                            </Text>
                        </Box>
                        <Box width="100%">
                            <TextField
                                label="Paste Video Link:"
                                placeholder={`Paste your ${clipSourceType.replace(/\b\w/g, char => char.toUpperCase())} Video Link here`}
                                autoComplete="off"
                                value={clipSourceUrl}
                                onChange={(value) => {
                                    videoLiveSellingStore.clipSourceUrl = value;
                                    videoLiveSellingStore.hasSourceUrlErr();
                                }}
                                error={clipSourceUrlErr}
                                disabled={
                                    videoUrl != '' ||
                                    savingPopclip ||
                                    isImportingReel
                                }
                                connectedRight={
                                    <InlineStack blockAlign="center">
                                        {isImportingReel && (
                                            <Box paddingInline="200">
                                                <Spinner size="small" />
                                            </Box>
                                        )}
                                        {!editClipData && videoUrl ? (
                                            <Button
                                                tone="critical"
                                                variant="primary"
                                                onClick={onRemoveInstaVideo}
                                                disabled={
                                                    savingPopclip ||
                                                    isImportingReel
                                                }
                                            >
                                                Delete & import a new clip
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="primary"
                                                onClick={
                                                    getClipFromExternalSource
                                                }
                                                disabled={
                                                    clipSourceUrl == '' ||
                                                    clipSourceUrlErr != '' ||
                                                    savingPopclip ||
                                                    isImportingReel ||
                                                    isEditMode
                                                }
                                            >
                                                Import
                                            </Button>
                                        )}
                                    </InlineStack>
                                }
                            />
                        </Box>
                    </BlockStack>
                ) : (
                    <AppVideoPicker
                        labelStr="Upload Video"
                        videoUrl={videoUrl}
                        hintText="Maximum upload file size: 100MB"
                    />
                )}
                <Box padding="200" paddingBlockEnd="0">
                    <InlineStack gap="200">
                        <Checkbox
                            label="Feature this Popclip on the home page of your store and grab immediate attention of your customers"
                            checked={isFeatured}
                            onChange={(value) => videoLiveSellingStore.isFeatured = value}
                            disabled={savingPopclip}
                        />
                        <Link onClick={() => setShowFeaturePopclipIntro(true)}>Learn More</Link>
                    </InlineStack>
                </Box>
            </BlockStack>
        </PopClipsCard>
    );
};

export default ClipsFormCard;
