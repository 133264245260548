import { BlockStack, Box, InlineStack, Spinner, Text } from '@shopify/polaris';
import Plot from 'react-plotly.js';
import DashboardHeaderSection from './DashboardHeaderSection';
import DashboardIndicatorTag from './DashboardIndicatorTag';
import { CONFIG, LAYOUT, LINE_SMOOTHING_VALUE } from './chartConstantConfig';
import { useState } from 'react';
import VfsModal from '../../v2/Components/VfsModal';

interface DashboardTotalCliksChartPropsTypes {
    title: string;
    value: number;
    chartData: number[];
    labels: string[];
    isLoading: boolean;
    growthInValue: number;
}

const DashboardTotalCliksChart = ({
    title,
    value,
    chartData,
    labels,
    isLoading,
    growthInValue,
}: DashboardTotalCliksChartPropsTypes) => {
    const [isExpand, setIsExpand] = useState(false);

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    const DrawTotalClickChart = () => {
        return (
            <Plot
                data={[
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'none',
                        fill: 'tozeroy',
                        fillcolor: 'rgba(86, 84, 212, 0.1)',
                        hoverinfo: 'skip',
                    },
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'lines',
                        line: {
                            color: '#5654D4',
                            width: 3,
                            shape: 'spline',
                            smoothing: LINE_SMOOTHING_VALUE,
                        },
                        hovertemplate: '%{y}<extra></extra>',
                    },
                ]}
                layout={{
                    ...LAYOUT,
                    xaxis: {
                        visible: true,
                        showgrid: false,
                        title: 'Weeks',
                    },
                    yaxis: {
                        visible: true,
                        showgrid: false,
                        showticklabels: false,
                        title: 'Clicks',
                        hoverformat: '.f',
                    },
                    width: isExpand ? '' : 400,
                    autosize: isExpand,
                }}
                config={{
                    ...CONFIG,
                    displayModeBar: isExpand,
                    staticPlot: !isExpand,
                }}
            />
        );
    };
    return (
        <div>
            <Box
                borderWidth="025"
                borderColor="border"
                borderRadius="500"
                padding="600"
                background="bg-fill"
                shadow="200"
            >
                <DashboardHeaderSection
                    title={title}
                    showResizeButton={true}
                    isExpand={isExpand}
                    handleResizeChart={handleResizeChart}
                />
                <InlineStack
                    blockAlign="end"
                    align="space-between"
                    wrap={false}
                >
                    <BlockStack gap="400">
                        <Text variant="heading3xl" as="h2">
                            {value}
                        </Text>
                        <DashboardIndicatorTag
                            growthInValue={growthInValue}
                            isMonth={false}
                        />
                    </BlockStack>
                    {isLoading ? (
                        <Box width="100%">
                            <InlineStack align="center" blockAlign="center">
                                <Spinner />
                            </InlineStack>
                        </Box>
                    ) : (
                        <DrawTotalClickChart />
                    )}
                </InlineStack>
            </Box>
            {isExpand && (
                <VfsModal
                    onCloseModal={handleResizeChart}
                    title={title}
                    modalWidth="90%"
                >
                    <Box width="100%">
                        <BlockStack align="start">
                            <InlineStack blockAlign="end" wrap={false}>
                                <BlockStack gap="400">
                                    <Text variant="heading3xl" as="h2">
                                        {value}
                                    </Text>
                                    <DashboardIndicatorTag
                                        growthInValue={growthInValue}
                                        isMonth={false}
                                    />
                                </BlockStack>
                            </InlineStack>
                            {isLoading ? (
                                <InlineStack align="center" blockAlign="center">
                                    <Spinner />
                                </InlineStack>
                            ) : (
                                <DrawTotalClickChart />
                            )}
                        </BlockStack>
                    </Box>
                </VfsModal>
            )}
        </div>
    );
};

export default DashboardTotalCliksChart;
