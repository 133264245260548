import {
    BlockStack,
    Box,
    Button,
    FooterHelp,
    InlineStack,
    Layout,
    Link,
    Page,
    Text,
    Tabs,
} from '@shopify/polaris';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { mobileRoutes, vfsRoutes } from '@/constants/routes.constants';
import {
    buildMobileAppStore,
    defaultMobileAppConfigStore,
    mobileAppConfigStore,
    shopCollectionStore,
    shopStore,
} from '@/stores';
import { useGetAppConfig } from '@/hooks/useGetAppConfig';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { useUpdateAppConfig } from '@/hooks/useUpdateAppConfig';
import { SaveBar } from '@shopify/app-bridge-react';
import { useGetAppSetup } from '@/hooks/useGetAppSetup';
import { useGetAppRequest } from '@/hooks/useGetAppRequest';
import ZendeskSupportWidget from '../common/ZendeskSupportWidget';
import { AppProgressCard } from '../build-mobile-app/AppProgressCard';
import { BYPASS_GATING_FOR_DOMAINS } from '@/constants/shopify.constants';

export interface AppBuilderBaseLayoutProps {
    title: string;
    subtitle?: string;
    buttonLabel?: string;
    onHandleButton?: () => void;
    buttonDisabled?: boolean;
    selectedTabId?: number;
    handleTabChange?: () => number;
    isDisabled?: false;
}

export interface Tabs {
    id: number;
    content?: string;
}

const AppBuilderBaseLayout = (
    props: PropsWithChildren<AppBuilderBaseLayoutProps>,
) => {
    const [isAndroidAvailable, setIsAndroidAvailable] = useState(false);
    const navigate = useNavigate();
    const { updateAppConfig } = useUpdateAppConfig();
    const { getAppConfig } = useGetAppConfig();
    const { getAppSetup } = useGetAppSetup();
    const { getAppRequests } = useGetAppRequest();
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    const { isAppRequestSubmitted, iosLink, androidLink } =
        useSnapshot(buildMobileAppStore);

    const tabs: Tabs[] = [
        {
            id: 1,
            content: 'Notifications/Banners',
        },
        {
            id: 2,
            content: 'Appearance',
        },
        {
            id: 3,
            content: 'Custom Menu',
        },
        {
            id: 4,
            content: 'Setup',
        },
    ];

    if (isAppRequestSubmitted && (iosLink || androidLink)) {
        tabs.pop();
    }

    const handleTabChange = useCallback((selectedTabIndex: number) => {
        switch (selectedTabIndex) {
            case 0:
                navigate(mobileRoutes.notification);
                break;
            case 1:
                navigate(mobileRoutes.appearence);
                break;
            case 2:
                navigate(mobileRoutes.custom_menu);
                break;
            case 3:
                navigate(mobileRoutes.setup);
                break;

            default:
                break;
        }
    }, []);

    type ConfigKeys = keyof typeof mobileAppConfigStore;
    const keysToCompare: ConfigKeys[] = [
        'primaryColor',
        'bannerColor',
        'secondaryColor',
        'customFont',
        'bannerTitle',
        'bannerText',
        'headerLogoUrl',
        'liveBannerTitle',
        'liveBannerText',
        'liveBannerColor',
        'menuHandle',
        'shopMenuHandle',
        'customLinksHandle',
        'shopifyCollectionGid',
    ];

    const isValidPlayloadValues = () => {
        let flag = true;
        if (mobileAppConfigStore.showInventoryLimit < 0) {
            flag = false;
            shopify.toast.show('Please enter positive in inventory display.', {
                isError: true,
                duration: 5000,
            });
        }
        return flag;
    };

    const handleSubmitBtn = async () => {
        mobileAppConfigStore.showFormErrorLabel = true;
        const {
            primaryColor,
            secondaryColor,
            bannerColor,
            customFont,
            bannerTitle,
            bannerText,
            headerLogoUrl,
            liveBannerTitle,
            liveBannerText,
            liveBannerColor,
            menuHandle,
            shopMenuHandle,
            customLinksHandle,
            showInventoryLimit,
        } = mobileAppConfigStore;
        try {
            if (shopCollectionStore.selectedCollection === null) {
                shopify.toast.show(
                    'Please select a featured collection before saving.',
                    {
                        isError: true,
                        duration: 5000,
                    },
                );
            }
            if (
                shopCollectionStore.selectedCollection !== null &&
                isValidPlayloadValues()
            ) {
                const response = await updateAppConfig({
                    collectionId: shopCollectionStore.selectedCollection || '',
                    primaryColor: primaryColor,
                    bannerColor: bannerColor,
                    secondaryColor: secondaryColor,
                    font: customFont,
                    bannerTitle: bannerTitle,
                    bannerText: bannerText,
                    headerLogoUrl: headerLogoUrl,
                    liveBannerTitle: liveBannerTitle,
                    liveBannerText: liveBannerText,
                    liveBannerColor: liveBannerColor,
                    menuHandle: menuHandle,
                    shopMenuHandle: shopMenuHandle,
                    customLinksHandle: customLinksHandle,
                    showInventoryLimit: showInventoryLimit,
                });
                if (response?.data) {
                    shopify.toast.show('Successfully saved the changes', {
                        isError: false,
                        duration: 5000,
                    });
                    getAppConfig();
                    shopify.saveBar.hide('mobile-save-bar');
                } else {
                    shopify.toast.show(
                        'Failed to save the changes. Please try again or contact support.',
                        {
                            isError: true,
                            duration: 5000,
                        },
                    );
                }
            }
        } catch (error) {
            console.error(error);
            shopify.toast.show('Failed. Please try again or contact support.', {
                isError: true,
                duration: 5000,
            });
        }
    };

    const handleDiscardBtn = () => {
        keysToCompare.forEach((key) => {
            mobileAppConfigStore[key] = defaultMobileAppConfigStore[key];
        });
        mobileAppConfigStore.showInventoryLimit =
            defaultMobileAppConfigStore.showInventoryLimit;
        shopify.saveBar.hide('mobile-save-bar');
    };

    const isConfigModified = () => {
        return keysToCompare.some(
            (key) =>
                mobileAppConfigSnap[key] !== defaultMobileAppConfigStore[key],
        );
    };

    const goToLandingPage = () => {
        handleDiscardBtn();
        navigate(vfsRoutes.landing);
    };

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            'mobile',
            true,
        );
        const maxPrice = Math.max(...addonPrices);

        if (BYPASS_GATING_FOR_DOMAINS.includes(shopStore.shopifyDomain)) {
            setIsAndroidAvailable(true);
        } else {
            setIsAndroidAvailable(maxPrice > 79.99);
        }
    }, [shopStore.subscription]);

    useEffect(() => {
        mobileAppConfigStore.showNotificationErrorLabel = false;
        mobileAppConfigStore.showFormErrorLabel = false;
        mobileAppConfigStore.isLoading = true;
        getAppConfig();
        getAppSetup();
        getAppRequests();

        () => {
            keysToCompare.forEach((key) => {
                mobileAppConfigStore[key] = defaultMobileAppConfigStore[key];
            });
            shopify.saveBar.hide('mobile-save-bar');
        };
    }, []);

    useEffect(() => {
        if (buildMobileAppStore.isAppRequestSubmitted) {
            const isInventoryModified =
                mobileAppConfigSnap.showInventoryLimit !==
                    defaultMobileAppConfigStore.showInventoryLimit &&
                mobileAppConfigSnap.showInventoryLimit >= 0 &&
                mobileAppConfigSnap.showInventoryLimit < 100;
            isInventoryModified || isConfigModified()
                ? shopify.saveBar.show('mobile-save-bar')
                : shopify.saveBar.hide('mobile-save-bar');
        }
    }, [
        mobileAppConfigSnap.primaryColor,
        mobileAppConfigSnap.bannerColor,
        mobileAppConfigSnap.secondaryColor,
        mobileAppConfigSnap.customFont,
        mobileAppConfigSnap.bannerTitle,
        mobileAppConfigSnap.bannerText,
        mobileAppConfigSnap.headerLogoUrl,
        mobileAppConfigSnap.liveBannerTitle,
        mobileAppConfigSnap.liveBannerText,
        mobileAppConfigSnap.liveBannerColor,
        mobileAppConfigSnap.shopifyCollectionGid,
        mobileAppConfigSnap.menuHandle,
        mobileAppConfigSnap.shopMenuHandle,
        mobileAppConfigSnap.customLinksHandle,
        mobileAppConfigSnap.showInventoryLimit,
    ]);

    return (
        <Page fullWidth>
            <SaveBar id="mobile-save-bar">
                <button variant="primary" onClick={handleSubmitBtn}></button>
                <button onClick={handleDiscardBtn}></button>
            </SaveBar>
            <Box paddingInline="3200">
                <Layout>
                    <Layout.Section>
                        <BlockStack gap="800">
                            <BlockStack inlineAlign="center" gap="1000">
                                <Box width="60rem">
                                    <InlineStack>
                                        <Box width="100%">
                                            <BlockStack gap="100">
                                                <InlineStack
                                                    gap="100"
                                                    wrap={false}
                                                >
                                                    <Button
                                                        disabled={
                                                            props.isDisabled
                                                        }
                                                        icon={ArrowLeftIcon}
                                                        accessibilityLabel="Back to landing page"
                                                        size="large"
                                                        variant="monochromePlain"
                                                        onClick={
                                                            goToLandingPage
                                                        }
                                                    />
                                                    {/* <Text
                                                        as="h1"
                                                        variant="heading2xl"
                                                    >
                                                        {props.title}
                                                    </Text> */}
                                                    <Box width="60%">
                                                        <Tabs
                                                            fitted={true}
                                                            tabs={tabs}
                                                            selected={
                                                                props.selectedTabId
                                                            }
                                                            onSelect={
                                                                handleTabChange
                                                            }
                                                        />
                                                    </Box>
                                                </InlineStack>
                                                <Text as="h2" variant="bodyLg">
                                                    {props.subtitle}
                                                </Text>
                                            </BlockStack>
                                        </Box>
                                        {props.buttonLabel ? (
                                            <Box
                                                width="10%"
                                                paddingBlockStart="300"
                                            >
                                                <Button
                                                    fullWidth={true}
                                                    variant="primary"
                                                    submit
                                                    onClick={
                                                        props.onHandleButton
                                                    }
                                                    size="large"
                                                    disabled={
                                                        props.buttonDisabled
                                                    }
                                                >
                                                    {props.buttonLabel}
                                                </Button>
                                            </Box>
                                        ) : null}
                                    </InlineStack>
                                </Box>
                            </BlockStack>
                            <BlockStack gap="1000" inlineAlign='center'>
                                {isAppRequestSubmitted && buildMobileAppStore.customerIssueDescription ? (
                                    <AppProgressCard 
                                        bannerTone={buildMobileAppStore.customerIssueBannerTone}
                                        status={buildMobileAppStore.customerIssueStatus}
                                        description={buildMobileAppStore.customerIssueDescription}
                                        link={true}
                                    />
                                ) : null}{isAppRequestSubmitted && buildMobileAppStore.blockedIssueDescription ? (
                                    <AppProgressCard 
                                        bannerTone={buildMobileAppStore.blockedIssueBannerTone}
                                        status={buildMobileAppStore.blockedIssueStatus}
                                        description={buildMobileAppStore.blockedIssueDescription}
                                        link={true}
                                    />
                                ) : null}
                                {isAppRequestSubmitted && buildMobileAppStore.iosStatus ? (
                                    <AppProgressCard 
                                        bannerTone={buildMobileAppStore.iosBannerTone}
                                        status={buildMobileAppStore.iosStatus}
                                        description={buildMobileAppStore.iosDescription}
                                    />
                                ) : null}
                                {isAppRequestSubmitted && buildMobileAppStore.androidStatus && isAndroidAvailable ? (
                                    <AppProgressCard 
                                        bannerTone={buildMobileAppStore.androidBannerTone}
                                        status={buildMobileAppStore.androidStatus}
                                        description={buildMobileAppStore.androidDescription}
                                    />
                                ) : null}
                            </BlockStack>
                            {props.children}
                            <Box insetBlockEnd="0">
                                <FooterHelp>
                                    <BlockStack gap="100" inlineAlign="center">
                                        <Text as="p">
                                            Need help at any step? Reach out to{' '}
                                            <Link url="mailto:support@videeo.live">
                                                support@videeo.live
                                            </Link>{' '}
                                            |{' '}
                                            <Link url="https://help.videeo.live">
                                                help.videeo.live
                                            </Link>
                                        </Text>
                                    </BlockStack>
                                </FooterHelp>
                            </Box>
                            <ZendeskSupportWidget />
                        </BlockStack>
                    </Layout.Section>
                </Layout>
            </Box>
        </Page>
    );
};

export default AppBuilderBaseLayout;
