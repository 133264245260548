import { useCallback } from 'react';
import { useMediaGalleryAuthFetch } from './useAuthenticatedFetch';
import { videoLiveSellingStore } from '@/stores';
import shopStore from '@/stores/shopStore';

type ApiResponse = {
    id: string;
    metaIdentifier: string;
    metaData: {
        featured: boolean;
        size: string | null;
        imageURL: string;
        alt: string;
        available: boolean;
        caption: string;
        description: string;
        title: string;
        type: string;
        mediatype: string;
        status: string;
        tags: string;
        videoURL: string;
        gifURL: string;
        thumbnailURL: string;
        processedURL: string;
    };
    cdnUrl: string;
    updateMedia: boolean;
};

export const useUpdateClip = () => {
    const fetch = useMediaGalleryAuthFetch();

    const updateClip = useCallback(
        async () => {
            const apiUrl = 'csUpdateMedia';
            const firebaseUID = shopStore.popshopliveSellerId;
            const productsArr =
                videoLiveSellingStore.shopifySelectedProducts.map((prod) => {
                    return {
                        defaultImage: prod.imgSrc,
                        shopifyProductId: prod.id,
                        shopifyProductHandle: prod.handle,
                        shopifyProductUrl: `https://${shopStore.shopifyDomain}/products/${prod.handle}`,
                    };
                });

            const {editClipData, uploadedPopClipData, clipSourceUrl, clipSourceType} = videoLiveSellingStore;
            const metaData = editClipData ? editClipData.metaData : uploadedPopClipData.clipMetaData;

            const {
                videoURL,
                gifURL,
                thumbnailURL,
                processedURL,
                type,
                featured,
                status = 'uploaded',
            } = metaData;

            const dataObj = {
                id: firebaseUID,
                metaIdentifier: editClipData
                    ? editClipData.metaIdentifier
                    : uploadedPopClipData.clipMetaIdentifier,
                metaData: {
                    isPrivate: true,
                    products: productsArr,
                    shopifyDomain: shopStore.shopifyDomain,
                    videoTitle: videoLiveSellingStore.clipTitle,
                    sellerId: firebaseUID,
                    videoURL: videoURL || null,
                    clipSourceUrl,
                    clipSourceType,
                    gifURL: gifURL || null,
                    thumbnailURL,
                    processedURL,
                    type,
                    featured,
                    status,
                    display: videoLiveSellingStore.isClipExcluded
                        ? []
                        : ['WEB_PDP', 'MOBILE_PDP', 'MOBILE_WEB_PDP'],
                },
                updateMedia: false,
            };

            const response = await fetch<ApiResponse>(apiUrl, {
                method: 'POST',
                body: JSON.stringify(dataObj),
            });

            return response;
        },
        [fetch],
    );
    return { updateClip };
};