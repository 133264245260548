import { useEffect, useState } from 'react';
import { BlockStack, Box } from '@shopify/polaris';
import {
    IOSChecklist,
    AppNameCard,
    AppIconLaunchScreen,
    SubmitCard,
    SupportInformation,
} from '@/components/build-mobile-app';
import { useGetAppSetup } from '@/hooks/useGetAppSetup';
import { useGetAppConfig } from '@/hooks/useGetAppConfig';
import { useGetAppRequest } from '@/hooks/useGetAppRequest';
import { useGetAppleEmail } from '@/hooks/useGetAppleEmail';
import { FeaturedCollectionCard } from '@/components/build-mobile-app/FeaturedCollectionCard';
import { buildMobileAppStore} from '@/stores';
import { useSnapshot } from 'valtio';
import AppBuilderBaseLayout from '@/components/layout/AppBuilderBaseLayout';
import { useLocation } from 'react-router-dom';
import { mobileRoutes } from '@/constants/routes.constants';

const MobileAppSetupPage = () => {
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });
    const { getAppSetup } = useGetAppSetup();
    const { getAppConfig } = useGetAppConfig();
    const { getAppRequests } = useGetAppRequest();
    const { getAppleEmail } = useGetAppleEmail();
    const [selected, setSelected] = useState<number>(0);
    const { pathname } = useLocation();
    const { isAppRequestSubmitted } = buildMobileAppSnapshot;

    useEffect(() => {
        getAppSetup();
        getAppConfig();
        getAppRequests();
        getAppleEmail();
        if (pathname.includes(mobileRoutes.setup)) {
            setSelected(3);
        }
        window.scrollTo(0, 0);
    }, []);

    return (
        <AppBuilderBaseLayout
            title="Design your Videeo Mobile App"
            subtitle={
                !isAppRequestSubmitted
                    ? 'Fill out the form below with required information to start building your app'
                    : ''
            }
            buttonLabel=""
            selectedTabId={selected}
        >
            <BlockStack inlineAlign="center">
                <Box width="60rem">
                    <BlockStack gap="100">
                        <BlockStack gap="1000">
                            {!isAppRequestSubmitted &&
                            !(
                                buildMobileAppStore.iosLink ||
                                buildMobileAppStore.androidLink
                            ) ? (
                                <IOSChecklist />
                            ) : null}
                            <SupportInformation />
                            <AppNameCard />
                            <AppIconLaunchScreen />
                            {!isAppRequestSubmitted &&
                            !(
                                buildMobileAppStore.iosLink ||
                                buildMobileAppStore.androidLink
                            ) ? (
                                <FeaturedCollectionCard
                                    readonly={
                                        buildMobileAppSnapshot.isAppRequestSubmitted
                                    }
                                />
                            ) : null}
                            {!isAppRequestSubmitted &&
                            !(
                                buildMobileAppStore.iosLink ||
                                buildMobileAppStore.androidLink
                            ) ? (
                                <SubmitCard />
                            ) : null}
                        </BlockStack>
                    </BlockStack>
                </Box>
            </BlockStack>
        </AppBuilderBaseLayout>
    );
};

export default MobileAppSetupPage;
