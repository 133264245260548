import { useCallback } from 'react';
import { useVFSAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { buildMobileAppStore, shopStore } from '@/stores';

export type AppRequestsResponse = {
    androidLink: string;
    androidLiveVersion: string;
    appleBlocked: boolean;
    appleIssue: string;
    applePendingValidation: true;
    blocked: boolean;
    deliveryState: number;
    iconIssue: string;
    iosLink: string;
    iosLiveVersion: string;
    launchIssue: string;
    nameIssue: string;
    pendingValidation: true;
    pipelineShopName: string;
    productsIssue: string;
    supportUrlIssue: string;
    appleDevTeamId: string;
    desiredAppName: string;
    altAppNameOne: string;
    altAppNameTwo: string;
    iconS3Url: string;
    launchS3Url: string;
    supportUrl: string;
    androidStatus: string;
    iosStatus: string;
    customerIssue: string;
};

const statusDescription = (response, isAndroid = true) => {
    let status;
    let description;
    let bannerTone;
    const value = isAndroid ? response?.androidStatus : response?.iosStatus;

    switch (value.toUpperCase()) {

        case "PENDING":
        case "IN PROGRESS":
        case "NEW":
            bannerTone = "info";
            status = "App Approval Pending";
            description = "Your app build is currently in progress.";
            break;
            
        case "LIVE":
            bannerTone = "success";
            status = "App Live";
            description = `Your App is live! ${isAndroid ? response?.androidLink || '' : response?.iosLink || ''}`;
            break;
    
        case "BLOCKED":
            bannerTone = "critical";
            status = "Approval Blocked";
            description = "Your app is currently blocked due to one or more reasons. Please reach out to support.";
            break;

        case "REVIEW":
            bannerTone = "info";
            status = "App Under Review";
            description = `Your app is submitted to ${isAndroid ? 'Google' : 'Apple'} and awaiting for review.`;
            break;

        default:
            break;
    }
    if(isAndroid){
        buildMobileAppStore.androidBannerTone = bannerTone || 'info';
        buildMobileAppStore.androidStatus = status ? 'Android ' + status : '';
        buildMobileAppStore.androidDescription = description || '';
    } else {
        buildMobileAppStore.iosBannerTone = bannerTone || 'info';
        buildMobileAppStore.iosStatus = status ? 'iOS ' + status : '';
        buildMobileAppStore.iosDescription = description || '';
    }
}

export const useGetAppRequest = () => {
    const fetchVFSAuthFetch = useVFSAuthFetch();
    const { appRequestId, appRequestSkIdentifier } = shopStore;
    const getAppRequests = useCallback(async () => {
        try {
            const params = `${
                appRequestId ? `?appRequestId=${appRequestId}` : ''
            }${
                appRequestSkIdentifier
                    ? `&appRequestSkId=${appRequestSkIdentifier}`
                    : ''
            }`;
            let response = await fetch<AppRequestsResponse>(
                `/api/shopify/shop/mobile/app-request${
                    buildMobileAppStore.iosLink ||
                    buildMobileAppStore.androidLink
                        ? ''
                        : params
                }`,
                {},
            );
            response = await response.json();            
            if (response.data) {
                if (appRequestId && appRequestSkIdentifier || (response.data.androidLink || response.data.iosLink)) {
                    buildMobileAppStore.isAppRequestSubmitted = true;
                }
                buildMobileAppStore.setAppleDevTeamId(
                    response.data.appleDevTeamId,
                );
                buildMobileAppStore.setDesiredAppName(
                    response.data.desiredAppName,
                );
                buildMobileAppStore.setDesiredAppNameSecond(
                    response.data.altAppNameOne,
                );
                buildMobileAppStore.setDesiredAppNameThird(
                    response.data.altAppNameTwo,
                );
                buildMobileAppStore.appLogoUrl = response.data.iconS3Url;
                buildMobileAppStore.launchScreenImageUrl =
                    response.data.launchS3Url;
                buildMobileAppStore.supportURL = response.data.supportUrl;
                buildMobileAppStore.iosLink = response.data.iosLink;
                buildMobileAppStore.androidLink = response.data.androidLink;
                buildMobileAppStore.androidAppStatus = response.data?.androidStatus || "";
                buildMobileAppStore.iosAppStatus = response.data?.iosStatus || "";

                if(response?.data?.androidLink){
                    buildMobileAppStore.androidBannerTone = 'success';
                    buildMobileAppStore.androidStatus ='Android App Live';
                    buildMobileAppStore.androidDescription = `Your App is live! ${response?.data?.androidLink || ''}`;
                }
                if(response?.data?.iosLink){
                    buildMobileAppStore.iosBannerTone = 'success';
                    buildMobileAppStore.iosStatus = 'iOS App Live';
                    buildMobileAppStore.iosDescription = `Your App is live! ${response?.data?.iosLink || ''}`;
                }
                if(appRequestId && appRequestSkIdentifier){
                    statusDescription(response.data, true);
                    statusDescription(response.data, false);
                }
                if(response?.data?.customerIssue){
                    buildMobileAppStore.customerIssueBannerTone = 'critical';
                    buildMobileAppStore.customerIssueStatus = "Customer Action Required";
                    buildMobileAppStore.customerIssueDescription = `${response?.data?.customerIssue || ''}.`;
                }
                if(response?.data?.blocked){
                    buildMobileAppStore.blockedIssueBannerTone = 'critical';
                    buildMobileAppStore.blockedIssueStatus = "Approval Blocked";
                    buildMobileAppStore.blockedIssueDescription = `${response?.data?.blocked || ''}.`;
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            buildMobileAppStore.isAppRequestLoading = false;
        }
    }, [fetchVFSAuthFetch]);

    return { getAppRequests };
};
