import { usePopclipsPagination } from "@/hooks/usePopclipsPagination";
import { popclipsStore } from "@/stores";
import { ListMediaByIdResponse } from "@/types/ListMediaByIdResponse";
import { Modal, TitleBar } from "@shopify/app-bridge-react";
import { BlockStack, Box, Button, Grid, InlineStack, Spinner, Text } from "@shopify/polaris";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import CarouselClip from "./CarouselClip";
import { useSnapshot } from "valtio";
import { RefreshIcon } from "@shopify/polaris-icons";

type CarouselClipModalProps = {
    openModal: boolean;
    toggleOpenModal: () => void;
    selectedClips: string[];
    handleSelectClip: (clipsData: any) => void;
    isEditMode?: boolean;
};

export default function CarouselClipModal({
    openModal,
    selectedClips,
    toggleOpenModal,
    handleSelectClip,
    isEditMode,
}: CarouselClipModalProps) {
    let timeoutId: number;
    const location = useLocation();
    const { getPaginatedData } = usePopclipsPagination(12);
    const { carouselCurrentPage, carouselLastEvalKey, clipsForEditCarousel } = useSnapshot(popclipsStore);

    const [clipDataArray, setClipDataArray] = useState<ListMediaByIdResponse[]>(
        [],
    );
    const [isApiLoading, setIsApiLoading] = useState(true);
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [tempSelectedClips, setTempSelectedClips] =
        useState<string[]>(selectedClips);

    useEffect(() => {
        if (openModal) {
            setTempSelectedClips(selectedClips);
        }
    }, [openModal]);

    useEffect(() => {
        setIsApiLoading(true);
        const params = location.state;
        const defaultEvalKeyObj = { 1: undefined };
        const { carouselCurrentPage, carouselLastEvalKey } = popclipsStore;
        const loadFirstPage =
            carouselCurrentPage == 1 || (params && params.goToFirstPage);

        const paginationPromise = loadFirstPage
            ? getPaginatedData()
            : getPaginatedData(false, carouselLastEvalKey[carouselCurrentPage]);

        paginationPromise.then((response) => {
            if (response) {
                setIsDataAvailable(response.isDataAvailable);
                setClipDataArrayWithClips(response.clips);

                if (response.lastEvaluatedKey && loadFirstPage) {
                    Object.assign(defaultEvalKeyObj, {
                        [carouselCurrentPage + 1]: response.lastEvaluatedKey,
                    });
                    popclipsStore.carouselLastEvalKey = defaultEvalKeyObj;
                }
            }
            setIsApiLoading(false);
        });
    }, []);

    const nextPageClicked = () => {
        window.clearTimeout(timeoutId);
        setIsApiLoading(true);
        const { carouselCurrentPage, carouselLastEvalKey } = popclipsStore;
        let cursor = carouselLastEvalKey
            ? carouselLastEvalKey[carouselCurrentPage + 1]
            : undefined;

        getPaginatedData(false, cursor).then((response) => {
            if (response) {
                setIsDataAvailable(response.isDataAvailable);
                setClipDataArrayWithClips(response.clips);

                popclipsStore.carouselCurrentPage =
                    popclipsStore.carouselCurrentPage + 1;

                if (response.lastEvaluatedKey) {
                    let tempVar = carouselLastEvalKey;
                    if (tempVar)
                        tempVar[carouselCurrentPage + 2] =
                            response.lastEvaluatedKey;

                    popclipsStore.carouselLastEvalKey = tempVar;
                }
            }
            setIsApiLoading(false);
        });
    };

    const setClipDataArrayWithClips = (clips: ListMediaByIdResponse[]) => {
        if (isEditMode) {
            const metaIds = (clipsForEditCarousel?.length > 0 && clipsForEditCarousel.map((clip) => clip?.metaIdentifier)) || [];
            const responseClips = (clips?.length > 0 && clips.filter((clip) => clip?.metaIdentifier && !metaIds.includes(clip.metaIdentifier))) || [];

            setClipDataArray((prev) => [...prev, ...responseClips]);
        } else {
            setClipDataArray((prev) => [...prev, ...clips]);
        }
    };

    const showShowNextPageBtn = useMemo(() => {
        const pageToGo = carouselCurrentPage + 1;
        return carouselLastEvalKey[pageToGo] ? true : false;
    }, [carouselCurrentPage, carouselLastEvalKey]);

    const handleAddSelectedClips = () => {
        const clips = (clipDataArray?.length > 0 && clipDataArray.filter((clip) =>
            clip?.metaIdentifier && tempSelectedClips.includes(clip.metaIdentifier),
        )) || [];
        if (isEditMode) {
            const oldClips = clipsForEditCarousel.filter((clip) => clip?.metaIdentifier && tempSelectedClips.includes(clip.metaIdentifier));
            handleSelectClip([...oldClips, ...clips]);
        } else {
            handleSelectClip(clips);
        }

        toggleOpenModal();
    };

    return (
        <Modal open={openModal} variant="large" onHide={toggleOpenModal}>
            <TitleBar
                title={`All Popclips (${tempSelectedClips.length} Selected)`}
            >
                {isDataAvailable && (
                    <button
                        variant="primary"
                        onClick={handleAddSelectedClips}
                        disabled={tempSelectedClips.length == 0}
                    >
                        Add{' '}
                        {tempSelectedClips.length > 0
                            ? tempSelectedClips.length
                            : ''}{' '}
                        Selected Popclips
                    </button>
                )}
                {isDataAvailable && (
                    <button
                        disabled={tempSelectedClips.length == 0}
                        onClick={() => setTempSelectedClips([])}
                    >
                        Reset Selection
                    </button>
                )}
                {!isDataAvailable && (
                    <button onClick={toggleOpenModal} variant="primary">
                        Cancel
                    </button>
                )}
            </TitleBar>
            <Box padding="400">
                {isApiLoading && clipDataArray.length == 0 ? (
                    <BlockStack align="center" inlineAlign="center">
                        <Spinner size="large" />
                    </BlockStack>
                ) : isDataAvailable && openModal ? (
                    <>
                        <Grid columns={{ xs: 3, sm: 4, md: 6, lg: 6, xl: 6 }}>
                            {(isEditMode ? [...clipsForEditCarousel, ...clipDataArray] : clipDataArray).map(
                                (clip) => (
                                    <Grid.Cell key={clip?.metaIdentifier}>
                                        <CarouselClip
                                            clipData={clip}
                                            selectedClips={tempSelectedClips}
                                            handleSelectClip={(clipData: any) =>
                                                setTempSelectedClips((prev) => [
                                                    ...prev,
                                                    clipData?.metaIdentifier || '',
                                                ])
                                            }
                                            handleRemoveClip={(
                                                clipId: string,
                                            ) =>
                                                setTempSelectedClips((prev) =>
                                                    prev.filter(
                                                        (id) => id !== clipId,
                                                    ),
                                                )
                                            }
                                        />
                                    </Grid.Cell>
                                ),
                            )}
                        </Grid>
                        {isDataAvailable && showShowNextPageBtn && (
                            <Box paddingBlockStart="400">
                                <InlineStack
                                    gap="200"
                                    align="center"
                                    blockAlign="center"
                                >
                                    {isApiLoading &&
                                        clipDataArray.length > 0 && (
                                            <Spinner size="small" />
                                        )}
                                    <Button
                                        icon={RefreshIcon}
                                        variant="primary"
                                        onClick={nextPageClicked}
                                        disabled={isApiLoading}
                                    >
                                        Load More
                                    </Button>
                                </InlineStack>
                            </Box>
                        )}
                    </>
                ) : (
                    <InlineStack gap="200" align="center" blockAlign="center">
                        <Box padding="400">
                            <Text as="h1" variant="headingMd">
                                No Popclips found
                            </Text>
                        </Box>
                    </InlineStack>
                )}
            </Box>
        </Modal>
    );
};
