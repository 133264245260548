import { shopStore } from "@/stores";
import { useNavigate } from "react-router-dom";
import AlertBannerComponent from "../AlertBannerComponent";
import { vfsRoutes } from "@/constants/routes.constants";
import { useSnapshot } from "valtio";

export default function PopclipsFeatureGatingBanner() {
    const navigate = useNavigate();
    const { featureGatingData } = useSnapshot(shopStore);

    if (featureGatingData?.popclips?.maxViews == '-1') return;

    return (
        <div style={{ marginBlock: featureGatingData?.popclips?.limitAccess ? '10px' : 'unset' }}>
            {featureGatingData?.popclips?.limitAccess && (
                <AlertBannerComponent
                    type="info"
                    title={
                        "You've reached your current plan's view limits of " +
                        featureGatingData?.popclips?.maxViews
                    }
                    message="You have hit your maximum view limit for Popclips in the current
                    billing period. To continue creating new Popclips for your
                    customers, please upgrade your plan."
                    buttonText="Upgrade your plan here"
                    primaryAction={() => navigate(vfsRoutes.managePlans)}
                    size="large"
                />
            )}
        </div>
    );
}