import { useCallback } from 'react';
import shopStore from '@/stores/shopStore';
import { SHOPIFY_SF_API_VERSION } from '@/constants/shopify.constants';

export const useShopifyStorefront = () => {
    const getMenusByHandle = useCallback(
        async (menuHandle: string): Promise<[]> => {
            const { shopifyDomain } = shopStore;
            if (!shopifyDomain) return [];

            try {
                const shopifyURL = `https://${shopStore.shopifyDomain}/api/${SHOPIFY_SF_API_VERSION}/graphql.json`;
                const fetchAPIOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token':
                            shopStore.storefrontAccessToken,
                    },
                    body: JSON.stringify(prepareGetMenusByHandle(menuHandle)),
                };

                const res = await fetch(shopifyURL, fetchAPIOptions);
                if (res.ok) {
                    const { data } = await res.json();
                    if (data && data.menu) {
                        return Promise.resolve(data.menu.items);
                    } else {
                        return [];
                    }
                }

                throw new Error('Failed to get menus by handle.');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        [shopStore.shopifyDomain],
    );

    return { getMenusByHandle };
};

const prepareGetMenusByHandle = (menuHandle: string) => {
    return {
        query: `
                query MenuQuery($handle: String!) {
                    menu(handle: $handle) {
                        items {
                          id
                          title
                          url
                          type
                          resourceId
                          resource {
                            ... on Collection {
                              id
                              handle
                            }
                          }
                        }
                      }
                }
            `,
        variables: { handle: menuHandle },
    };
};
