import {
    Box,
    TextField,
    Divider,
    BlockStack,
    SkeletonBodyText,
    InlineStack,
    Button,
} from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import { buildMobileAppStore } from '@/stores';

import { CollapsibleCardWithTitleSection } from '@/components/common';

const AppNameCard = () => {
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });

    const handleNext = () => {
        if (
            buildMobileAppStore.appleDevTeamId &&
            buildMobileAppStore.desiredAppName &&
            buildMobileAppStore.desiredAppNameSecond &&
            buildMobileAppStore.desiredAppNameThird
        ) {
            buildMobileAppStore.setIsAppIconLaunchScreenCardOpen(true);
            return;
        } else {
            buildMobileAppStore.hasAppleDevTeamIdErr();
            buildMobileAppStore.hasDesiredAppNameErr();
            buildMobileAppStore.hasDesiredAppNameSecondErr();
            buildMobileAppStore.hasDesiredAppNameThirdErr();
            return;
        }
    };
    return (
        <CollapsibleCardWithTitleSection
            title="App Name"
            subtitle="The name of your app must be unique and not already taken by another app in Apple's App Store or Google Play. By default, we set it to the name of your shop."
            showCheck={false}
            open={buildMobileAppSnapshot.isAppNameCardOpen}
            onToggleOpen={buildMobileAppStore.setIsAppNameCardOpen}
            hideSubtitleWhenClosed={true}
        >
            {buildMobileAppSnapshot.isAppRequestLoading ? (
                <SkeletonBodyText />
            ) : (
                <Box>
                    <BlockStack gap="600">
                        <Divider />
                        <Box>
                            <TextField
                                autoComplete="off"
                                readOnly={
                                    buildMobileAppSnapshot.isAppRequestSubmitted
                                }
                                label="Company Name"
                                requiredIndicator
                                type="text"
                                maxLength={255}
                                value={buildMobileAppSnapshot.companyName}
                                onChange={(value) => {
                                    buildMobileAppStore.setCompanyName(
                                        value,
                                    );
                                    buildMobileAppStore.hasCompanyNameErr();
                                }}
                                size="medium"
                                onFocus={() => {
                                    buildMobileAppStore.companyNameErr = '';
                                }}
                                error={buildMobileAppSnapshot.companyNameErr}
                            />
                        </Box>
                        <Box>
                            <TextField
                                autoComplete="off"
                                readOnly={
                                    buildMobileAppSnapshot.isAppRequestSubmitted
                                }
                                label="Apple Developer Team ID (not an email, can be found in membership details on developer.apple.com)"
                                requiredIndicator
                                type="text"
                                maxLength={255}
                                value={buildMobileAppSnapshot.appleDevTeamId}
                                onChange={(value) => {
                                    buildMobileAppStore.setAppleDevTeamId(
                                        value,
                                    );
                                    buildMobileAppStore.hasAppleDevTeamIdErr();
                                }}
                                size="medium"
                                onFocus={() => {
                                    buildMobileAppStore.appleDevTeamIdErr = '';
                                }}
                                error={buildMobileAppSnapshot.appleDevTeamIdErr}
                            />
                        </Box>
                        <Box>
                            <TextField
                                autoComplete="off"
                                readOnly={
                                    buildMobileAppSnapshot.isAppRequestSubmitted
                                }
                                label="Desired App Name"
                                requiredIndicator
                                type="text"
                                maxLength={255}
                                value={buildMobileAppSnapshot.desiredAppName}
                                onChange={(value) => {
                                    buildMobileAppStore.setDesiredAppName(
                                        value,
                                    );
                                    buildMobileAppStore.hasDesiredAppNameErr();
                                }}
                                size="medium"
                                onFocus={() => {
                                    buildMobileAppStore.desiredAppNameErr = '';
                                }}
                                error={buildMobileAppSnapshot.desiredAppNameErr}
                            />
                        </Box>
                        <Box>
                            <TextField
                                autoComplete="off"
                                readOnly={
                                    buildMobileAppSnapshot.isAppRequestSubmitted
                                }
                                label="Desired App Name (Second choice)"
                                requiredIndicator
                                type="text"
                                maxLength={255}
                                value={
                                    buildMobileAppSnapshot.desiredAppNameSecond
                                }
                                onChange={(value) => {
                                    buildMobileAppStore.setDesiredAppNameSecond(
                                        value,
                                    );
                                    buildMobileAppStore.hasDesiredAppNameSecondErr();
                                }}
                                size="medium"
                                onFocus={() => {
                                    buildMobileAppStore.desiredAppNameSecondErr =
                                        '';
                                }}
                                error={
                                    buildMobileAppSnapshot.desiredAppNameSecondErr
                                }
                            />
                        </Box>
                        <Box>
                            <TextField
                                autoComplete="off"
                                readOnly={
                                    buildMobileAppSnapshot.isAppRequestSubmitted
                                }
                                label="Desired App Name (Third choice)"
                                requiredIndicator
                                type="text"
                                maxLength={255}
                                value={
                                    buildMobileAppSnapshot.desiredAppNameThird
                                }
                                onChange={(value) => {
                                    buildMobileAppStore.setDesiredAppNameThird(
                                        value,
                                    );
                                    buildMobileAppStore.hasDesiredAppNameThirdErr();
                                }}
                                size="medium"
                                onFocus={() => {
                                    buildMobileAppStore.desiredAppNameThirdErr =
                                        '';
                                }}
                                error={
                                    buildMobileAppSnapshot.desiredAppNameThirdErr
                                }
                            />
                        </Box>
                        <InlineStack align="end">
                            <Button
                                variant="primary"
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        </InlineStack>
                    </BlockStack>
                </Box>
            )}
        </CollapsibleCardWithTitleSection>
    );
};

export default AppNameCard;
