import {
    shopCollectionStore,
} from '@/stores';
import { useCallback } from 'react';
import shopStore from '@/stores/shopStore';
import { SHOPIFY_SF_API_VERSION } from '@/constants/shopify.constants';

export const useGetCollections = () => {
    const getCollections = useCallback(
        async (title: string) => {
            const body = {
                query: `
                    query collectionSearch($query: String) {
                            collections(query: $query, first: 20) {
                                edges {
                                        node {
                                                id
                                                title
                                        }
                                }
                            }

                    }
                `,
            };
            if (title) {
                body.variables = {
                    query: `title:${title}*`,
                };
            }
            try {
                shopCollectionStore.areCollectionsLoading = true;
                const res = await fetch(
                    `https://${shopStore.shopifyDomain}/api/${SHOPIFY_SF_API_VERSION}/graphql.json`,
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'X-Shopify-Storefront-Access-Token':
                                shopStore.storefrontAccessToken,
                        },
                        body: JSON.stringify(body),
                    },
                );
                if (res.ok) {
                    const { data } = (await res.json()) as {
                        data: {
                            collections: {
                                edges: {
                                    node: {
                                        id: string;
                                        title: string;
                                    };
                                }[];
                            };
                        };
                    };
                    shopCollectionStore.collections =
                        data.collections.edges.map(({ node }) => ({
                            value: node.id,
                            label: node.title,
                        }));
                    return Promise.resolve();
                }
                throw new Error('Failed to get collections');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            } finally {
                shopCollectionStore.areCollectionsLoading = false;
            }
        },
        [],
    );
    return { getCollections };
};
