import { Box, Button, InlineStack, TextField } from '@shopify/polaris';
import { MaximizeIcon, MinimizeIcon } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';

interface DashboardHeaderSectionProps {
    title: string;
    showResizeButton?: boolean;
    isExpand?: boolean;
    handleResizeChart?: () => void;
    enableSearchFeature?: boolean;
    placeholder?: string;
    handleSearchData?: (searchQuery: string) => void;
}

const DashboardHeaderSection = ({
    title,
    showResizeButton = false,
    isExpand = false,
    handleResizeChart,
    enableSearchFeature = false,
    placeholder,
    handleSearchData,
}: DashboardHeaderSectionProps) => {
    const [searchQuery, setSearchQuery] = useState('');

    const debounce = (func: (...args: any[]) => void, delay: number) => {
        let timer: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const debouncedSearch = useCallback(
        debounce((query: string) => handleSearchData(query), 300),
        [handleSearchData]
      );

    const handleSearchChange = (value: string) => {
        setSearchQuery(value);
        debouncedSearch(value);
    };

    return (
        <Box paddingBlockEnd="400">
            <InlineStack align="space-between" blockAlign="center">
                <InlineStack gap="200" blockAlign="center">
                    <div
                        style={{
                            fontFamily: 'clashDisplay',
                            fontSize: '20px',
                            fontWeight: 600,
                            lineHeight: '30px',
                        }}
                    >
                        {title}
                    </div>
                </InlineStack>
                <InlineStack gap="200" blockAlign="center" align='end'>
                    {enableSearchFeature && (
                        <TextField
                            label=""
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder={placeholder}
                            clearButton
                            onClearButtonClick={() => handleSearchChange('')}
                            autoComplete="off"
                        />
                    )}
                    {showResizeButton && (
                        <Button
                            icon={isExpand ? MinimizeIcon : MaximizeIcon}
                            onClick={handleResizeChart}
                        />
                    )}
                </InlineStack>
            </InlineStack>
        </Box>
    );
};

export default DashboardHeaderSection;
