import './vfsButton.scss';

interface CommonButtonProps {
    children: React.ReactNode;
    onClick: () => void;
    type?: 'button' | 'submit' | 'reset';
    variant?: 'primary' | 'secondary' | 'danger' | 'disabled';
    disabled?: boolean;
    className?: string;
    padding?: string;
}

const VfsButton = ({
    children,
    onClick,
    type = 'button',
    variant = 'primary',
    disabled = false,
    className = '',
    padding = '12px',
}: CommonButtonProps) => {
    const buttonClass = `__vfs-button ${variant} ${
        disabled ? 'disabled' : ''
    } ${className}`;

    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={buttonClass}
            style={{ padding: padding}}
        >
            {children}
        </button>
    );
};

export default VfsButton;
