import { DateTime } from "luxon";

export const formatDate = (date: Date) => {
    date = new Date(date);
    return date.toLocaleString(navigator.language, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
};

export const formatDateToLocalTime = (utcDateString: string): string => {
    const utcDate = new Date(utcDateString);
    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    const localDateString = utcDate.toLocaleString('en-US', options);
    const [monthDayPart, timePart] = localDateString.split(', ');
    const [month, day] = monthDayPart.split(' ');
    const formattedMonth = month.toUpperCase() + ',';
    const formattedDate = `${formattedMonth} ${day} - ${timePart}`;

    return formattedDate;
};

export const formatTimeToDateInLocalTime = (timestamp: number | null): string | number => {
    if (timestamp === null) {
        return '';
    }

    if (isNaN(timestamp) || timestamp < 0) {
        return timestamp;
    }

    try {
        return DateTime.fromMillis(timestamp)
        .toFormat("LLL, dd - h:mm a")
        .toUpperCase();
    } catch (error) {
        return timestamp;
    }
}

export const formatTimeDateYearInLocalTime = (timestamp: number | null): string | number  => {
    if (timestamp === null || isNaN(timestamp)) {
      return 'Invalid timestamp';
    }
  
    try {
      // Create a Date object from the timestamp
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        return timestamp;
      }
  
      // Extract components
      const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits for day
      const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase(); // Short month in uppercase
      const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year
      const time = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
  
      // Format into the desired format
      return `${day}-${month}-${year} ${time}`;
    } catch (error) {
      return timestamp;
    }
  }
  