import DashboardHome from '@/components/dashboard/DashboardHome';
import { BaseLayout } from '../v2/pages/Layout/BaseLayout';
import { Box, Divider, Frame, Loading } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { shopStore } from '@/stores';

const InsightsPage = () => {
    const [embedDashboardUrl, setEmbedDashboardUrl] = useState<string | null>(
        null,
    );
    const [isLoading, setIsLoading] = useState(false);

    const fetchEmbedInformation = async () => {
        if (!shopStore?.featureFlags?.looker_dashboard) {
            return;
        }
        setIsLoading(true);
        try {
            const dashboardId = 737;
            const subdomain = 'data';
            const response = await fetch(
                `/api/shopify/shop/cs-reports?dashboardId=${dashboardId}&subdomain=${subdomain}`,
                {
                    method: 'GET',
                },
            );

            const { data } = await response.json();            
            if (data?.url) {
                setEmbedDashboardUrl(data.url);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchEmbedInformation();
    }, []);

    return (
        <BaseLayout title="Analytics" backToLink="/">
            {shopStore?.featureFlags?.popclips_analytics_dashboard && (
                <>
                    <DashboardHome />
                    <Box paddingBlock="400">
                        <Divider />
                    </Box>
                </>
            )}
            {shopStore?.featureFlags?.looker_dashboard && (
                <>
                    {embedDashboardUrl && !isLoading ? (
                        <iframe
                            src={embedDashboardUrl}
                            style={{ width: '100%', height: '98vh' }}
                        ></iframe>
                    ) : isLoading ? (
                        <div style={{ height: '100px' }}>
                            <Frame>
                                <Loading />
                            </Frame>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            Data not found
                        </div>
                    )}
                </>
            )}
        </BaseLayout>
    );
};

export default InsightsPage;
