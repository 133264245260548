import { commaSeprateValue } from '@/utils/MoneyFormatter';
import { Box, Divider, Grid, InlineStack, Text } from '@shopify/polaris';
import Plot from 'react-plotly.js';

const style = {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#878787',
    textWrap: 'nowrap'
};
const HeaderSection = () => (
    <div
        style={{
            fontFamily: 'clashDisplay',
            fontSize: '36px',
            fontWeight: 600,
            lineHeight: '54px',
        }}
    >
        Summary
    </div>
);

const MetricCard = ({
    color,
    label,
    value,
    unit,
}: {
    color: string;
    label: string;
    value: number;
    unit: string;
}) => (
    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
        <InlineStack blockAlign="start" align="start" gap="400" wrap={false}>
            <Box>
                <div
                    style={{
                        background: color,
                        width: '4px',
                        height: '16px',
                        borderRadius: '2px',
                    }}
                />
            </Box>
            <div style={style}>{label}</div>
        </InlineStack>
        <Box paddingBlockStart="050">
            <InlineStack blockAlign="center" align="start" gap="100">
                    <Text variant="headingXl" as="p">
                        {value}
                    </Text>
                <span style={style}>{unit}</span>
            </InlineStack>
        </Box>
    </Grid.Cell>
);

const DashboardSummaryChart = ({ data }: { data: Record<string, number> }) => {
    const metrics = [
        {
            color: '#3FC8E4',
            label: 'Total Views',
            value: data?.total_views || 0,
            unit: 'views',
        },
        {
            color: '#F37E18',
            label: 'Total Add to Carts',
            value: data?.total_add_to_carts || 0,
            unit: 'orders',
        },
        {
            color: '#5654D4',
            label: 'Total Clicks',
            value: data?.total_clicks || 0,
            unit: 'clicks',
        },
        {
            color: '#FB3B52',
            label: 'Total Sales',
            value: commaSeprateValue(data?.total_sales || 0) +' USD',
            unit: '',
        },
        {
            color: '#E8D73F',
            label: 'Total Popclips Uploaded',
            value: data?.total_popclips_uploaded || 0,
            unit: 'clips',
        },
    ];

    const chartData = [
        {
            values: [1],
            labels: [''],
            type: 'pie',
            hole: 0.65,
            marker: { colors: Array(3).fill('rgba(0, 0, 0, 0.1)') },
            textinfo: 'none',
            hoverinfo: 'none',
            domain: { x: [0.01, 0.99], y: [0.01, 0.99] },
        },
        {
            values: [
                data?.total_views || 0,
                data?.total_add_to_carts || 0,
                data?.total_clicks || 0,
                data?.total_sales || 0,
                data?.total_popclips_uploaded || 0,
            ],
            labels: [
                'Total Views',
                'Total Add to Carts',
                'Total Clicks',
                'Total Sales',
                'Total Popclips Uploaded',
            ],
            type: 'pie',
            hole: 0.7,
            marker: {
                colors: ['#3FC8E4', '#F37E18', '#5654D4', '#FB3B52', '#E8D73F'],
            },
            textinfo: 'none',
            hoverinfo: 'label+value',
        },
    ];

    const chartLayout = {
        title: '',
        showlegend: false,
        width: 260,
        height: 260,
        margin: { l: 0, r: 0, t: 0, b: 20 },
    };

    return (
        <Box
            borderWidth="025"
            borderColor="border"
            borderRadius="500"
            padding="600"
            background="bg-fill"
            shadow="200"
        >
            <Box paddingBlockEnd="400">
                <HeaderSection />
            </Box>
            <Box paddingBlock="100">
                <Divider />
            </Box>
            <InlineStack
                gap="200"
                blockAlign="center"
                align="space-between"
                wrap={false}
            >
                <Box paddingBlock="400" width="60%">
                    <Grid
                        gap={{
                            xs: '30px',
                            sm: '30px',
                            md: '20px',
                            lg: '30px',
                            xl: '30px',
                        }}
                    >
                        {metrics.map((metric, index) => (
                            <MetricCard key={index} {...metric} />
                        ))}
                    </Grid>
                </Box>
                <div style={{ marginTop: '-130px', width: '40%' }}>
                    <Plot
                        data={chartData}
                        layout={chartLayout}
                        config={{
                            responsive: true,
                            displayModeBar: false,
                            displaylogo: false,
                        }}
                    />
                </div>
            </InlineStack>
        </Box>
    );
};

export default DashboardSummaryChart;
